import React, {
  useCallback,
  useEffect,
  useContext,
  useRef,
  useState,
} from "react";
import { useBeforeUnload, useNavigate, useBlocker } from "react-router-dom";
import AppContext from "../../AppContext";
import {
  Avatar,
  AvatarGroup,
  Box,
  Container,
  IconButton,
  InputBase,
  Rating,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { Button } from "../basic/Button";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import { AttachmentsForPost } from "../basic/AttachmentsForPost";
import { generateClient, post } from "aws-amplify/api";
import ImageCarousel from "../complex/ImageCarousel.jsx";
import { ActivityPostCard } from "../complex/ActivityPostCard";
import { ActivityCard } from "../complex/ActivityCard.jsx";
import uploadImages from "./sharedFunctions/uploadImages";
import CloseIcon from "@mui/icons-material/Close";
import timestampdifference from "../utils/timestampdifference";
import { LinkAttachment } from "../basic/LinkAttachment.jsx";
import { cleanText } from "../../ui-components/utils.js";
import LinearProgressWithLabel from "../complex/LinearProgressWithLabel";

const client = generateClient();

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});
const acceptableFiles = ["jpeg", "jpg", "png"];

const ResponsiveStack = styled(Stack)(({ theme }) => ({
  //maxWidth: 530,
  // marginLeft: 'auto',
  // marginRight: 'auto',
  // borderBottom: 'solid 1px rgba(0,0,0,0.15)',
  background: "white",
  [theme.breakpoints.down("md")]: {
    paddingLeft: 16,
    paddingRight: 16,
    borderBottom: 0,
    marginBottom: 8,
  },
  "&:hover": {
    cursor: "pointer",
    // background: 'rgba(0,0,0,0.025)'
  },
}));

/**
 * Primary UI component for user interaction
 */
export const CreatePostScreen = ({ ...props }) => {
  const navigate = useNavigate();

  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [state, setState] = useState({ blockState: false });
  const [uploadProgress, setUploadProgress] = useState(null);

  const eventFlag = useRef(0);

  useBlocker(() => {
    if (p.submission.postID || p.disableBlock === true) {
      return false;
    } else {
      const response = window.confirm(
        "Post creation is not complete. Changes might not be saved!"
      );

      if (response) {
        p.disableBlock = true;
        p.submission = {
          imagesData: p.imagesData,
          files: p.files,
        };
      }
      return !response;
    }
  }, state.blockState);

  useEffect(() => {
    if (state.blockState === false && p.submission?.postID) {
      navigate(`/create-post/share/${p.submission?.postID}`);
      delete p.submission.postID;
      delete p.journey;
    }
  }, [state.blockState]);

  useBeforeUnload((event) => {
    if (state.blockState) {
      // If there are unsaved changes
      event.preventDefault();
      event.returnValue = ""; // This will trigger the browser's default confirmation dialog
    }
  });

  useEffect(() => {
    if (p.journey !== "create-post") {
      p.submission = {
        imagesData: p.imagesData,
        files: p.files,
      };

      setState({
        disabled: false,
        loading: false,
        submission: p.submission,
      });
      setGlobalState({
        ...globalState,
        submission: p.submission,
      });
    } else {
      setState({
        disabled: false,
        loading: false,
        submission: p.submission,
        blockState: true,
      });
    }

    p.journey = "create-post";
    p.disableBlock = false;
  }, []);

  const attachPhotoHandler = (event) => {
    var cancelFlag = 0;

    if (event._reactName === "onInput" && eventFlag.current === 1) return;
    if (event._reactName === "onInput") eventFlag.current = 1;

    // console.log(eventFlag.current)
    // console.log("event._reactName", event._reactName);

    const attachedFiles = Array.from(event.target?.files);

    if (attachedFiles.length >= 10 || p.submission.files?.length >= 10) {
      alert("Too many attachments. Max attachments is 10.");
      return;
    }

    if (attachedFiles) {
      // 1. Check file extensions
      attachedFiles.forEach((v, i) => {
        const file = v;
        const slashIndex = file.type.lastIndexOf("/");
        const fileExtension = file.type.substring(slashIndex + 1).toLowerCase();

        if (!acceptableFiles.includes(fileExtension)) {
          cancelFlag = 1;
          alert("This file format is not allowed.");
          return;
        }
      });
    }

    // 2.1. If wrong files attached, cancel process
    if (cancelFlag === 1) return;

    // 2.2. Otherwise begin image submission object
    if (event?.target?.files && cancelFlag === 0) {
      const imagesData = [];

      Array.from(event.target.files).forEach((file, i) => {
        // const file = event.target.files[0];
        const previewURL = URL.createObjectURL(file);
        imagesData.push({
          label: file.name,
          url: previewURL,
        });
      });

      p.submission.imagesData = p?.submission?.imagesData
        ? [...p?.submission?.imagesData, ...imagesData]
        : [];

      p.submission.files = p?.submission?.files
        ? [...p?.submission?.files, ...event?.target?.files]
        : [];

      setState({
        ...state,
        submission: p.submission,
      });
      setGlobalState({
        ...globalState,
        submission: p.submission,
      });
    }
  };

  const onAddLink = (URL) => {
    p.submission.link = URL;
    setState({ ...state, submission: p.submission });
    setGlobalState({
      ...globalState,
      submission: p.submission,
    });
  };

  const onUpdateBody = (event) => {
    p.submission.body = event.currentTarget.value;

    setState({
      ...state,
      submission: p.submission,
      blockState: true,
    });

    setGlobalState({
      ...globalState,
      submission: p.submission,
    });
  };

  const onClearTaggedActivity = () => {
    p.submission.selectedActivityAttachment = null;

    setGlobalState({
      ...globalState,
      submission: p.submission,
    });
  };

  const onClearTaggedPost = () => {
    p.submission.selectedPostAttachment = null;

    setGlobalState({
      ...globalState,
      submission: p.submission,
    });
  };

  const onClearImageAttachments = () => {
    p.submission.imagesData = null;
    p.submission.files = null;

    eventFlag.current = 0;
    // setState({
    //   ...state,
    //   submission: p.submission,
    // });

    setGlobalState({
      ...globalState,
      submission: p.submission,
    });
  };

  const onClearLink = () => {
    delete p.submission.link;

    setState({
      ...state,
      submission: p.submission,
    });

    setGlobalState({
      ...globalState,
      submission: p.submission,
    });
  };

  const createNewPost = async () => {
    setState({
      ...state,
      loading: true,
      uploadProgress: 10
    });
    if (p.submission?.body) {
      try {
        // 1. Create the post
        q.updateState(globalState);
        const res = await q.doQuery(
          "createNewPost",
          "Post is being created...",
          "An error occured",
          {
            submission: p.submission,
          },
          p,
          "PUBLISHED",
          JSON.stringify(globalState?.selectedUsers) || null,
          `"${cleanText(p.submission.body)}"`,
          p.submission?.link ? `link: {url: "${p.submission.link}"},` : "",
          p.submission?.selectedPostAttachment?.id &&
            `"${p.submission?.selectedPostAttachment?.id}"`,
          p.submission?.selectedActivityAttachment?.id &&
            `"${p.submission?.selectedActivityAttachment?.id}"`
        );

        // 2. Get the post id
        const postID = res.data?.createPost?.id;

        // 3. Upload attached images (if any)
        if (p.submission.files) {
          await uploadImages(
            "POST",
            p.submission.files,
            postID,
            "post",
            //Callback for when images are done uploading
            () => {
              setState({ ...state, blockState: false });
              p.submission.postID = postID;
              p.disableBlock = true;
              console.log("disableBlock", p.disableBlock)
            },
            (uploadProgress) => {
              // callback 2
              setUploadProgress(uploadProgress);
            }
          );
        } else {
          setState({ ...state, blockState: false });
          p.submission.postID = postID;
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const onClickBack = () => {
    props.setAppDialog({
      ...props.appDialog,
      confirmText: "Exit",
      dialogChildren: (
        <Stack px={3}>
          <Typography variant="body1">
            Are you sure you want to leave this page?
            <br />
            Your changes will not be saved.
          </Typography>
        </Stack>
      ),
      open: true,
      onConfirm: async () => {
        navigate(-1);
      },
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          top: 0,
          height: {
            xs: `calc(100vh - ${theme.spacing(18)})`,
            sm: `calc(100vh - ${theme.spacing(1)})`,
          },
          position: "sticky",
          overflow: "scroll",
        }}
      >
        <Container maxWidth={"sm"} sx={{ flexGrow: 1 }}>
          <ScreenNavHeader
            backButtonDisabled={globalState?.queryLoading}
            onClick={onClickBack}
            title="Create Post"
          >
            <Button
              onClick={createNewPost}
              disabled={state.loading || globalState?.queryLoading}
              size={"large"}
            >
              {(state.loading || globalState?.queryLoading) && (
                <CircularProgress
                  size={18}
                  sx={{
                    mr: 1,
                    ml: -0.5,
                    color: "rgba(0,0,0,0.25)",
                  }}
                />
              )}
              Post
            </Button>
          </ScreenNavHeader>
          <Box
            sx={{
              overflowY: "scroll",
              // maxHeight: {
              //   xs: "calc(100vh - 260px)",
              //   sm: "calc(100vh - 150px)",
              // },
              height: "100%",
            }}
          >
            <Stack pt={4} pb={1} direction={"row"} spacing={2}>
              {globalState?.userData?.dynamoDB?.givenName ? (
                <>
                  <Avatar
                    src={
                      globalState?.userData?.dynamoDB?.images?.items?.[0]?.url
                    }
                    sx={{ width: 48, height: 48 }}
                  />

                  <Stack>
                    <Typography variant="body1">
                      {globalState?.userData?.dynamoDB?.givenName}{" "}
                      {globalState?.userData?.dynamoDB?.familyName}
                    </Typography>
                    {/* <Rating
                      disabled
                      size="small"
                      name="simple-controlled"
                      value={props.rating}
                    /> */}
                  </Stack>
                </>
              ) : (
                <>
                  <Skeleton variant="circular" sx={{ width: 48, height: 48 }} />
                  <Stack direction="column" spacing={1}>
                    <Skeleton
                      variant="rounded"
                      sx={{ width: 150, height: 12 }}
                    />
                    <Skeleton
                      variant="rounded"
                      sx={{ width: 150, height: 12 }}
                    />
                  </Stack>
                </>
              )}
            </Stack>

            {/* Included persons */}
            <Stack
              sx={{}}
              mb={1}
              alignItems={"center"}
              direction={"row"}
              spacing={0.5}
            >
              <AvatarGroup
                sx={{ position: "relative", left: 0 }}
                total={globalState?.selectedUsersData?.length}
              >
                {globalState?.selectedUsersData?.map((attendee, i) => {
                  return (
                    <Avatar
                      key={`avatar-${i}`}
                      sx={{ width: 20, height: 20 }}
                      alt={`${attendee.givenName} ${attendee.familyName}`}
                      src={attendee.image}
                    />
                  );
                })}
              </AvatarGroup>

              {globalState?.selectedUsersData?.length > 0 && (
                <>
                  <Typography variant="body1">With</Typography>

                  {globalState?.selectedUsersData?.map((attendee, i) => {
                    if (
                      i + 1 < globalState?.selectedUsersData?.length ||
                      globalState?.selectedUsersData?.length === 1
                    ) {
                      return (
                        <>
                          <Typography variant="body1" color="primary.dark">
                            <strong>{attendee.givenName}</strong>
                          </Typography>
                          <Typography sx={{ marginLeft: "0 !important" }}>
                            {i + 1 <
                              globalState?.selectedUsersData.length - 1 && `,`}
                          </Typography>
                        </>
                      );
                    } else if (
                      i + 1 ===
                      globalState?.selectedUsersData?.length
                    ) {
                      return (
                        <>
                          <Typography sx={{ marginLeft: "0 !important" }}>
                            , and
                          </Typography>
                          <Typography variant="body1" color="primary.dark">
                            <strong>
                              {
                                globalState?.selectedUsersData[
                                  globalState?.selectedUsersData?.length - 1
                                ].givenName
                              }
                            </strong>
                          </Typography>
                        </>
                      );
                    }
                  })}
                </>
              )}
            </Stack>

            <Stack direction={"column"} sx={{ flexGrow: 1 }}>
              <InputBase
                onChange={onUpdateBody}
                multiline
                minRows={3}
                placeholder="What is on your mind?"
                value={state.submission?.body}
              />
            </Stack>

            {/* Link Preview */}
            {globalState?.submission?.link && (
              <LinkAttachment
                title="Link"
                onClearLink={onClearLink}
                link={globalState?.submission?.link}
              />
            )}

            {/* Attached activity */}
            {globalState?.submission?.selectedActivityAttachment &&
              (() => {
                const startTimestamp = globalState?.submission
                  ?.selectedActivityAttachment
                  ? new Date(
                      globalState?.submission?.selectedActivityAttachment.activityStart
                    )
                  : null;
                const endTimestamp = globalState?.submission
                  ?.selectedActivityAttachment
                  ? new Date(
                      globalState?.submission?.selectedActivityAttachment.activityEnd
                    )
                  : null;
                var start = { sDay: 0, sMonth: 0, sHour: 0, sMinute: 0 };
                var end = { eDay: 0, eMonth: 0, eHour: 0, eMinute: 0 };

                if (startTimestamp) {
                  start = {
                    sDay: startTimestamp.getDate(),
                    sMonth: startTimestamp.getMonth(),
                    sHour: String(startTimestamp.getHours()).padStart(2, "0"),
                    sMinute: String(startTimestamp.getMinutes()).padStart(
                      2,
                      "0"
                    ),
                  };
                }
                if (endTimestamp) {
                  end = {
                    eDay: endTimestamp.getDate(),
                    eMonth: endTimestamp.getMonth(),
                    eHour: String(endTimestamp.getHours()).padStart(2, "0"),
                    eMinute: String(endTimestamp.getMinutes()).padStart(2, "0"),
                  };
                }

                const { sDay, sMonth, sHour, sMinute } = start;
                const { eDay, eMonth, eHour, eMinute } = end;

                return (
                  <Box mb={4}>
                    <Box mb={2}>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Typography fontWeight={700}>
                          Tagged Activity
                        </Typography>
                        <IconButton
                          onClick={onClearTaggedActivity}
                          size="small"
                          variant="outlined"
                        >
                          <SvgIcon sx={{ width: 16, height: 16 }}>
                            <CloseIcon />
                          </SvgIcon>
                        </IconButton>
                      </Stack>
                    </Box>
                    <Stack direction={"column"} spacing={1}>
                      <ActivityCard
                        id={
                          globalState?.submission?.selectedActivityAttachment
                            ?.id
                        }
                        category={
                          globalState?.submission?.selectedActivityAttachment
                            .interest.parentInterest.title
                        }
                        userName={`${globalState?.submission?.selectedActivityAttachment.userObject.givenName} ${globalState?.submission?.selectedActivityAttachment.userObject.familyName}`}
                        text={
                          globalState?.submission?.selectedActivityAttachment
                            .description
                        }
                        currency={
                          globalState?.submission?.selectedActivityAttachment
                            .currency || ""
                        }
                        price={
                          globalState?.submission?.selectedActivityAttachment
                            .price
                            ? parseFloat(
                                globalState?.submission
                                  ?.selectedActivityAttachment.price
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : "FREE"
                        }
                        day={new Date(
                          globalState?.submission?.selectedActivityAttachment.activityStart
                        ).getDate()}
                        month={new Date(
                          globalState?.submission?.selectedActivityAttachment.activityStart
                        ).getMonth()}
                        privacy={
                          globalState?.submission?.selectedActivityAttachment
                            .privacy
                        }
                        startTime={`${sHour}:${sMinute}`}
                        endTime={`${eHour}:${eMinute}`}
                        participants={
                          globalState?.submission?.selectedActivityAttachment
                            .numberOfParticipants
                        }
                        rsvped={
                          globalState?.submission?.selectedActivityAttachment
                            .activityAttendance.items.length
                        }
                        address={
                          globalState?.submission?.selectedActivityAttachment
                            .address
                        }
                        userAvatar={
                          globalState?.submission?.selectedActivityAttachment
                            .userObject.images.items &&
                          globalState?.submission?.selectedActivityAttachment
                            .userObject.images.items[0]?.url
                        }
                        attendees={globalState?.submission?.selectedActivityAttachment.activityAttendance.items.slice(
                          0,
                          2
                        )}
                        remainingDays={timestampdifference(
                          new Date(),
                          globalState?.submission?.selectedActivityAttachment
                            .activityEnd
                        )}
                      />
                    </Stack>
                  </Box>
                );
              })()}

            {/* Attached post */}
            {globalState?.submission?.selectedPostAttachment &&
              [globalState?.submission?.selectedPostAttachment].map(
                (itemObj, i) => {
                  const {
                    id,
                    createdByID,
                    status,
                    text,
                    createdAt,
                    interest,
                    images,
                    link,
                    userObject,
                    nextToken,
                    activityObject,
                    postObject,
                    commentsCount,
                    reactions,
                    reactionsCount,
                    numberOfVotes,
                    sumOfVotes,
                    privacy,
                    postTagUsers,
                  } = itemObj;

                  const startTimestamp = activityObject
                    ? new Date(activityObject.activityStart)
                    : null;
                  const endTimestamp = activityObject
                    ? new Date(activityObject.activityEnd)
                    : null;
                  var start = { sDay: 0, sMonth: 0, sHour: 0, sMinute: 0 };
                  var end = { eDay: 0, eMonth: 0, eHour: 0, eMinute: 0 };

                  if (startTimestamp) {
                    start = {
                      sDay: startTimestamp.getDate(),
                      sMonth: startTimestamp.getMonth(),
                      sHour: String(startTimestamp.getHours()).padStart(2, "0"),
                      sMinute: String(startTimestamp.getMinutes()).padStart(
                        2,
                        "0"
                      ),
                    };
                  }
                  if (endTimestamp) {
                    end = {
                      eDay: endTimestamp.getDate(),
                      eMonth: endTimestamp.getMonth(),
                      eHour: String(endTimestamp.getHours()).padStart(2, "0"),
                      eMinute: String(endTimestamp.getMinutes()).padStart(
                        2,
                        "0"
                      ),
                    };
                  }

                  const { sDay, sMonth, sHour, sMinute } = start;
                  const { eDay, eMonth, eHour, eMinute } = end;

                  return (
                    <Box mb={4}>
                      <React.Fragment>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Typography fontWeight={700}>Tagged Post</Typography>
                          <IconButton
                            onClick={onClearTaggedPost}
                            size="small"
                            variant="outlined"
                          >
                            <SvgIcon sx={{ width: 16, height: 16 }}>
                              <CloseIcon />
                            </SvgIcon>
                          </IconButton>
                        </Stack>
                      </React.Fragment>

                      <ResponsiveStack key={`feedcard-${i}`} pt={2} px={4}>
                        <ActivityPostCard
                          id={id}
                          userAvatar={
                            userObject.images.items.length > 0 &&
                            userObject.images.items[0]?.url
                          }
                          userName={`${userObject.givenName} ${userObject.familyName}`}
                          image={images?.items?.[0]?.url}
                          imagesData={images?.items}
                          text={text}
                          commentsCount={commentsCount}
                          reactionsCount={reactionsCount}
                          likeReactionID={reactions?.items?.[0]?.id}
                          liked={
                            reactions?.items?.[0]?.type === "HEART"
                              ? true
                              : false
                          }
                          shareType={
                            (activityObject && "activity") ||
                            (postObject && "post") ||
                            (link && "link") ||
                            null
                          }
                          createdAt={createdAt}
                          postTagUsers={postTagUsers}
                          rating={
                            Math.round(
                              (userObject.sumOfVotes /
                                userObject.numberOfVotes) *
                                100
                            ) / 100
                          }
                          activityObject={
                            activityObject && {
                              category:
                                activityObject.interest.parentInterest.title,
                              userName: `${activityObject.userObject.givenName} ${activityObject.userObject.familyName}`,
                              text: activityObject.description,
                              currency: activityObject.currency || "",
                              price: activityObject.price
                                ? parseFloat(
                                    activityObject.price
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : "FREE",
                              day: sDay,
                              month: sMonth,
                              privacy: activityObject.privacy,
                              startTime: `${sHour}:${sMinute}`,
                              endTime: `${eHour}:${eMinute}`,
                              participants: activityObject.numberOfParticipants,
                              rsvped:
                                activityObject.activityAttendance.items.length,
                              address: activityObject.address,
                              userAvatar:
                                activityObject.userObject.images.items &&
                                activityObject.userObject.images.items[0]?.url,
                              attendees:
                                activityObject.activityAttendance.items.slice(
                                  0,
                                  2
                                ),
                              remainingDays: timestampdifference(
                                new Date(),
                                endTimestamp
                              ),
                            }
                          }
                          postObject={
                            postObject && {
                              imageSrc:
                                postObject?.activityObject?.userObject?.images
                                  ?.items?.[0]?.url,
                              username:
                                postObject.activityObject &&
                                `${postObject.activityObject.userObject.givenName} ${postObject.activityObject.userObject.familyName}`,
                              text:
                                postObject.text ||
                                (postObject.activityObject &&
                                  postObject.activityObject.description),
                              type: postObject.activityObject && "Activity",
                              name:
                                postObject.activityObject &&
                                postObject.activityObject.name,
                            }
                          }
                          link={
                            link && {
                              imageSrc: link?.imageUrl,
                              name: link?.title,
                              text: " ",
                              type: "link",
                              url: link.url,
                            }
                          }
                        />
                      </ResponsiveStack>
                    </Box>
                  );
                }
              )}

            {/* Images Preview */}
            {globalState?.submission?.imagesData && (
              <Stack mb={4} sx={{ maxWidth: { xs: 300, sm: 400 } }}>
                <React.Fragment>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Typography fontWeight={700}>Image(s)</Typography>
                    <IconButton
                      onClick={onClearImageAttachments}
                      size="small"
                      variant="outlined"
                    >
                      <SvgIcon sx={{ width: 16, height: 16 }}>
                        <CloseIcon />
                      </SvgIcon>
                    </IconButton>
                  </Stack>
                  <ImageCarousel
                    maxSize={{ xs: 300, sm: 400 }}
                    imagesData={globalState?.submission?.imagesData}
                  />
                </React.Fragment>
                {/* <img
            style={{maxHeight: 200, maxWidth: 200}}
            src={state.submission?.images?.[0]} /> */}
              </Stack>
            )}
          </Box>
        </Container>
      </Box>

      {uploadProgress 
      ? (
        <Stack
          sx={{
            position: { xs: "fixed", sm: "fixed" },
            zIndex: "20000",
            bottom: { xs: 64, sm: 64 },
            width: "100%",
          }}
          maxWidth={"sm"}
        >
          <Box px={4}>
            <LinearProgressWithLabel value={uploadProgress} />
          </Box>
        </Stack>
      ) : (
        <AttachmentsForPost
          disabled={state.loading || globalState?.queryLoading}
          onAddLink={onAddLink}
          attachPhotoHandler={attachPhotoHandler}
        />
      )}
    </ThemeProvider>
  );
};

export const CreatePostScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar pb={0}>
      <CreatePostScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

CreatePostScreen.propTypes = {};

CreatePostScreen.defaultProps = {
  color: "primary",
};
