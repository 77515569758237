import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import ImageContext from "../../ImageContext";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MUIButton from "@mui/material/Button";
import { Button } from "../basic/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = SwipeableViews;

function ImageCarousel(props) {

  const [previewImage, setPreviewImage] = useContext(ImageContext);
  const theme = useTheme();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = props.imagesData?.length || 0;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const onImageClickHandler = (event) => {
    setPreviewImage(
      event.currentTarget.getAttribute("url")
    );
    navigate(`/photos/${parseInt(event.currentTarget.getAttribute("index")+1)}`)
  }
  return (
    <Box
      sx={{
        maxWidth: props.maxSize || { xs: 568, sm: 568, md: 450, lg: 536 },
        margin: props.centered ? "0 auto" : 0,
        flexGrow: 1,
        position: "relative",
      }}
    >
      {props.withLabel && (
        <Paper
          square
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            height: 50,
            pl: 2,
            bgcolor: "background.default",
          }}
        >
          <Typography>{props.imagesData?.[activeStep]?.label}</Typography>
        </Paper>
      )}
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {props.imagesData?.map((step, index) => (
          <div 
            key={index} 
            index={index}
            url={`${step.url?.replace("//cdn.qa.sociogram.com/", "//d2mgxt9bsinkzr.cloudfront.net/filters:quality(100)/fit-in/1920x1080/")}`}
            onClick={onImageClickHandler}
          >
            {Math.abs(activeStep - index) <= 2 ? (
              <Stack 
                key={`stack-${index}`}
                sx={{
                  background: "rgba(0,0,0,0.9)",
                  alignItems: "center",
                  justifyContent: "center",
                  height:{ xs: 350}, // sm: 380, lg: 536 },
                  width: props.maxSize || "100%",
                  overflow: "hidden"
                }}
              >
                <Box
                  key={`image-${index}`}
                  sx={{
                    display: "block",
                    overflow: "hidden",
                    height: props.maxSize || { xs: 350},  //sm: 380, lg: 536 },
                    width: props.maxSize || { xs: 420, sm: 580, lg: 536 },
                    background: `url("${step.url?.replace("//cdn.qa.sociogram.com/", "//d2mgxt9bsinkzr.cloudfront.net/filters:quality(75)/fit-in/500x500/")}") center`,
                    backgroundSize: "cover",
                  }}
                  src={`${step.url?.replace("//cdn.qa.sociogram.com/", "//d2mgxt9bsinkzr.cloudfront.net/filters:quality(75)/fit-in/500x500/")}`}
                  alt={step.label}
                />
              </Stack>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <Box
        sx={{
          background: "rgba(0, 0, 0, 0.5)",
          position: "absolute",
          top: "12px",
          right: "12px",
          zIndex: 111,
          color: "white",
          borderRadius: "50%",
          height: "48px",
          width: "48px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="body2">{activeStep+1}/{maxSteps}</Typography>
      </Box>
      <MobileStepper
        steps={maxSteps}
        activeStep={activeStep}
        sx={{
          position: "absolute",
          background: "none",
          left: "50%",
          transform: "translate(-50%, -50%)",
          justifyContent: "center",
          bottom: 0,
          "& > div > div.MuiMobileStepper-dotActive ": {
            backgroundColor: "white",
          },
        }}
        nextButton={
         props.imagesData?.length > 1 && <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {/* Next */}
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          props.imagesData?.length > 1 && <Button 
          size="small" 
          color="secondary"
          onClick={handleBack} 
          disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            {/* Back */}
          </Button>
        }
      />
    </Box>
  );
}

export default ImageCarousel;
