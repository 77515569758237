import React, { useContext, useEffect, useState } from "react";
import { Link as ReactLink } from "react-router-dom";
import AppContext from "../../AppContext";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import { AvatarLink } from "../basic/AvatarLink";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import Badge from "@mui/material/Badge";
import { Logo } from "../basic/Logo";
import MessagesIconSubbed from "../complex/MessagesIconSubbed";

export function ScreenAppBar(props) {
  const [globalState] = useContext(AppContext);

  return (
    <React.Fragment>
      <AppBar
        sx={{
          top: 0,
          left: "50%",
          transform: "translate(-50%, 0)",
          color: "text.primary",
          background: "white",
          boxShadow: "none",
        }}
        {...props}
      >
        <Container sx={{ flexGrow: 1 }}>
          <Toolbar sx={{ py: 0.5 }} disableGutters>
            <Logo
              linkToHome={true}
              color="color"
              size={38}
              sxStack={{ flexGrow: 1 }}
            />

            <Box sx={{ display: { xs: "flex", md: "flex" } }}>
              <IconButton
                component={ReactLink}
                to="/messages"
                size="large"
                aria-label="show 4 new mails"
                color="inherit"
              >
                <MessagesIconSubbed />
              </IconButton>

              {globalState?.userData?.dynamoDB?.id ? (
                <AvatarLink
                  to={`/profile/${globalState?.userData?.dynamoDB?.id}`}
                  sx={{ width: 42, height: 42 }}
                  alt="A"
                  src={globalState?.userData?.dynamoDB?.images?.items[0]?.url}
                ></AvatarLink>
              ) : (
                <Skeleton variant="circular" width={42} height={42} />
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </React.Fragment>
  );
}
