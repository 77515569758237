import React, { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../AppContext.js";
import Badge from "@mui/material/Badge";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";

function MessagesIconSubbed(props) {
  const [
    globalState,
    setGlobalState,
    q,
    p,
    connectionStatus,
    isBackgroundLoaded,
    notificationsGlobalState,
  ] = useContext(AppContext);

  return (
    <Badge
      badgeContent={
        notificationsGlobalState?.newMessages?.length < 0
          ? 0
          : notificationsGlobalState?.newMessages?.filter(
              (obj) => obj?.status !== "SEEN"
            )?.length
      }
      color="error"
    >
      <ChatBubbleOutlineOutlinedIcon />
    </Badge>
  );
}

export default MessagesIconSubbed;
