import React, { useContext, useEffect, useState } from "react";
import {
  Link as ReactLink,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import AppContext from "../../AppContext";
// import Box from '@mui/material/Box';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { ActivityPostCard } from "../complex/ActivityPostCard";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import "./HomeScreen.css";
import timestampdifference from "../utils/timestampdifference";
import { generateClient, post } from "aws-amplify/api";
import getPost from "./queries/getPost.js";

const client = generateClient();

const theme = createTheme({
  palette: {
    primary: {
      main: "#2D9CDB",
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#E0C2FF",
      light: "#F5EBFF",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#47008F",
    },
  },
  typography: {
    fontFamily: [
      '"Lato"',
      "Roboto",
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

const ResponsiveBoxContainer = styled(Box)(({ theme }) => ({
  background: "solid 1px rgba(0,0,0,0.15)",
  margin: "0 auto",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    background: "#f6f7f9",
  },
}));

const ResponsiveStack = styled(Stack)(({ theme }) => ({
  //maxWidth: 530,
  // marginLeft: 'auto',
  // marginRight: 'auto',
  // borderBottom: 'solid 1px rgba(0,0,0,0.15)',
  background: "white",
  [theme.breakpoints.down("md")]: {
    paddingLeft: 16,
    paddingRight: 16,
    borderBottom: 0,
    marginBottom: 8,
  },
  "&:hover": {
    cursor: "pointer",
    // background: 'rgba(0,0,0,0.025)'
  },
}));

const ResponsiveDivider = styled(Divider)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export function PostViewScreen() {
  const location = useLocation();
  const params = useParams();

  const [state, setState] = useState({ data: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [globalState, setGlobalState] = useContext(AppContext);

  const fetchData = async () => {
    if (globalState?.homeNoRefresh) {
      // setGlobalState({...globalState, homeNoRefresh: false})
    }
    try {
      setIsLoading(true);
      // console.log('keys(globalState?.userData)', Object.keys(globalState?.userData))
      // console.log('globalState?.userData.userId', globalState?.userData.userId)

      const res = await client.graphql({
        query: getPost(globalState?.userData?.userId, params?.post),
      });

      setState({
        ...state,
        data: [res.data.getPost],
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (!globalState?.userData?.userId) return;
      fetchData(page);
    })();
  }, [page]);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"}>
        <ScreenNavHeader title="Post" to="/" />
      </Container>
      <ResponsiveBoxContainer>
        <div>
          {state.data.length === 0 &&
            [1].map((v, i) => (
              <ResponsiveStack
                sx={{ "&:hover": { background: "none" } }}
                key={`skeleton-activity-${i}`}
                direction={"column"}
                spacing={1}
                mb={0}
                py={2}
                px={4}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={1}
                  sx={{ width: "100%" }}
                >
                  <Skeleton
                    animation={"wave"}
                    variant="circular"
                    width={48}
                    height={48}
                  />
                  <Stack
                    direction={"row"}
                    spacing={1}
                    flexGrow={1}
                    justifyContent={"space-between"}
                  >
                    <Stack direction={"column"} spacing={1}>
                      <Skeleton variant="rounded" width={160} height={10} />
                      <Skeleton
                        variant="rounded"
                        width={100}
                        height={10}
                        sx={{ flexGrow: 1 }}
                      />
                    </Stack>
                    {/* <Skeleton variant="rounded" width={20} height={6} /> */}
                  </Stack>
                </Stack>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={"100%"}
                  height={170}
                />
              </ResponsiveStack>
            ))}
          {state.data &&
            state.data.map((itemObj, i) => {
              const {
                id,
                activityID,
                createdByID,
                status,
                text,
                createdAt,
                interest,
                images,
                link,
                userObject,
                activityObject,
                postObject,
                commentsCount,
                reactions,
                reactionsCount,
                numberOfVotes,
                sumOfVotes,
                privacy,
                postTagUsers,
              } = itemObj;

              const startTimestamp = activityObject
                ? new Date(activityObject.activityStart)
                : null;
              const endTimestamp = activityObject
                ? new Date(activityObject.activityEnd)
                : null;
              var start = { sDay: 0, sMonth: 0, sHour: 0, sMinute: 0 };
              var end = { eDay: 0, eMonth: 0, eHour: 0, eMinute: 0 };

              if (startTimestamp) {
                start = {
                  sDay: startTimestamp.getDate(),
                  sMonth: startTimestamp.getMonth(),
                  sHour: String(startTimestamp.getHours()).padStart(2, "0"),
                  sMinute: String(startTimestamp.getMinutes()).padStart(2, "0"),
                };
              }
              if (endTimestamp) {
                end = {
                  eDay: endTimestamp.getDate(),
                  eMonth: endTimestamp.getMonth(),
                  eHour: String(endTimestamp.getHours()).padStart(2, "0"),
                  eMinute: String(endTimestamp.getMinutes()).padStart(2, "0"),
                };
              }

              const { sDay, sMonth, sHour, sMinute } = start;
              const { eDay, eMonth, eHour, eMinute } = end;

              if (postObject) console.log("postObject", postObject);

              return (
                <>
                  <ResponsiveStack key={`feedcard-${i}`} pt={2} px={4}>
                    <ActivityPostCard
                      id={id}
                      activityID={activityID}
                      userObject={userObject}
                      userAvatar={
                        userObject.images.items.length > 0 &&
                        userObject.images.items[0]?.url
                      }
                      userName={`${userObject.givenName} ${userObject.familyName}`}
                      userId={userObject?.id}
                      image={images?.items?.[0]?.url}
                      imagesData={images?.items}
                      text={text}
                      commentsCount={commentsCount}
                      reactionsCount={reactionsCount}
                      likeReactionID={reactions?.items?.[0]?.id}
                      liked={
                        reactions?.items?.[0]?.type === "HEART" ? true : false
                      }
                      shareType={
                        (activityObject && "activity") ||
                        (postObject && "post") ||
                        (link && "link") ||
                        null
                      }
                      createdAt={createdAt}
                      postTagUsers={postTagUsers}
                      rating={
                        Math.round(
                          (userObject.sumOfVotes / userObject.numberOfVotes) *
                            100
                        ) / 100
                      }
                      activityObject={
                        activityObject && {
                          category:
                            activityObject.interest.parentInterest.title,
                          userName: `${activityObject.userObject.givenName} ${activityObject.userObject.familyName}`,
                          text: activityObject.description,
                          currency: activityObject.currency || "",
                          price: activityObject.price
                          ? parseFloat(
                              activityObject.price
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "FREE",
                          day: sDay,
                          month: sMonth,
                          privacy: activityObject.privacy,
                          startTime: `${sHour}:${sMinute}`,
                          endTime: `${eHour}:${eMinute}`,
                          participants: activityObject.numberOfParticipants,
                          rsvped:
                            activityObject.activityAttendance.items.length,
                          address: activityObject.address,
                          userAvatar:
                            activityObject?.userObject?.images?.items?.[0]?.url,
                          attendees:
                            activityObject.activityAttendance.items.slice(0, 5),
                          remainingDays: timestampdifference(
                            new Date(),
                            endTimestamp
                          ),
                        }
                      }
                      postObject={
                        postObject && {
                          imageSrc:
                            postObject.images?.items?.[0]?.url ||
                            postObject?.userObject?.images?.items?.[0]?.url,
                          username: `${postObject?.userObject?.givenName} ${postObject?.userObject?.familyName}`,
                          text:
                            `"${postObject.text}"` ||
                            postObject?.activityObject?.description,
                          type: postObject.activityObject && "Activity",
                          name: postObject?.activityObject?.name,
                        }
                      }
                      link={
                        link && {
                          imageSrc: link?.imageUrl,
                          name: link?.title,
                          text: " ",
                          type: "link",
                          url: link.url,
                        }
                      }
                    />
                  </ResponsiveStack>
                </>
              );
            })}
        </div>
      </ResponsiveBoxContainer>
    </ThemeProvider>
  );
}

export const PostViewScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar bg={"none"}>
      <PostViewScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};
