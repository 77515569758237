import { escapeSpecialChars, keywordQueryUsers } from '../../utils/keywordQueryUsers';

const searchPostsFuzzy = (keyword, userID) => `
searchPostsFuzzy(keyword: "${keyword}") {
  createdByID
  reactionsCount
  reactions(filter: {createdByID: {eq: "${userID}"}}) {
    nextToken
    items {
      id
      type
    }
  }
  status
  text
  commentsCount
  createdAt
  id
  images {
    items {
      assetEntityID
      url
      id
      index
    }
  }
  userObject {
    ratePrivacy
    id
    phoneNumber
    givenName
    familyName
    numberOfVotes
    sumOfVotes
    images(limit: 1) {
      items {
        id
        index
        url
      }
    }
  }
  link {
    imageUrl
    title
    url
  }
  postTagUsers {
    items {
      user {
        id
        givenName
        familyName
        phoneNumber
        images(limit: 1) {
          items {
            id
            url
            index
          }
        }
      }
    }
  }
  postObject {
    createdByID
    reactionsCount
    status
    text
    commentsCount
    createdAt
    id
    activityObject {
      name
      activityEnd
      activityStart
      address
      description
      currency
      id
      locationDescription
      numberOfParticipants
      price
      privacy
      interest {
        id
        parentID
        title
        parentInterest {
          id
          title
        }
      }
      userObject {
        ratePrivacy
        id
        phoneNumber
        givenName
        familyName
        numberOfVotes
        sumOfVotes
        images(limit: 1) {
          items {
            id
            index
            url
          }
        }
      }
      activityAttendance {
        items {
          activityID
          createdByID
          status
          initStatus
          user {
            id
            familyName
            givenName
            phoneNumber
            images(limit: 1) {
              items {
                id
                index
                url
              }
            }
          }
        }
      }
    }
    postTagUsers {
      items {
        user {
          id
          givenName
          familyName
          phoneNumber
          images(limit: 1) {
            items {
              id
              url
              index
            }
          }
        }
      }
    }
    userObject {
      ratePrivacy
      id
      phoneNumber
      givenName
      familyName
      numberOfVotes
      sumOfVotes
      images {
        items {
          id
          index
          url
        }
      }
    }
    link {
      imageUrl
      title
      url
    }
    images {
      items {
        assetEntityID
        url
        id
        index
      }
    }
  }
  activityObject {
    status
    paymentMethod
    createdByID
    activityEnd
    activityStart
    address
    description
    currency
    id
    locationDescription
    numberOfParticipants
    price
    privacy
    interest {
      id
      parentID
      parentInterest {
        id
        title
      }
    }
    userObject {
      ratePrivacy
      id
      phoneNumber
      givenName
      familyName
      numberOfVotes
      sumOfVotes
      images(limit: 1) {
        items {
          id
          index
          url
        }
      }
    }
    activityAttendance {
      items {
        activityID
        createdByID
        status
        initStatus
        user {
          id
          familyName
          givenName
          phoneNumber
          images(limit: 1) {
            items {
              id
              index
              url
            }
          }
        }
      }
    }
  }
}`;

const searchActivitiesFuzzySorted = (criteria, userID, sortTrue=false, rangeTrue=false) => `
searchActivitiesFuzzySorted(
  keyword: "${criteria}",
  currentDateTime: "${new Date().toISOString()}", 
  inequality: "gte",
  userId: "${userID}",
  sortTrue: ${sortTrue},
  rangeTrue: ${rangeTrue},
  limit: 50,
  direction: desc
) {
  items {
    id
    name
    description
    currency
    price
    address
    activityStart
    activityEnd
    privacy
    conditions
    numberOfParticipants
    interest {
      id
      parentID
      title
      parentInterest {
        id
        title
      }
    }
    userObject {
      familyName
      givenName
      id
      images(limit: 1) {
        items {
          url
        }
      }
      interests {
        selectedInterests
      }
      numberOfVotes
      numberOfInvitesSent
      status
    }
    paymentMethod
    paymentLink
    longitude
    locationDescription
    link {
      title
      url
      info
      imageUrl
    }
    images {
      items {
        url
        index
        id
      }
    }
    city
    cancellationReason
    activityAttendance {
      items {
        status
        user {
          familyName
          givenName
          id
          images(limit: 1) {
            items {
              url
            }
          }
        }
      }
    }
  }
  nextToken
}`;

const searchUsersFuzzy = (keyword) => `
searchUsersFuzzy(keyword: "${keyword}") {
  id
  givenName
  familyName
  sumOfVotes
  status
  privacy
  ratePrivacy
  numberOfVotes
  images(limit: 1) {
    items {
      url
    }
  }
}`;

export const keywordQueryActivities = (keyword) => {
  var kibanaAr = [];

  keyword.split(" ").forEach((kw) => {
      //  kibanaAr.push({ "fuzzy": { "description": { "value": `${kw}`, "fuzziness": 10 } } });
      //  kibanaAr.push({ "fuzzy": { "locationType": { "value": `${kw}`, "fuzziness": 5 } } });
  //     //  kibanaAr.push({ "fuzzy": { "address": { "value": `${kw}`, "fuzziness": 10, "boost": 2, } } });
       kibanaAr.push({ "fuzzy": { "pInterest": { "value": `${kw}`, "fuzziness": "AUTO", "boost": 2, } } });
       kibanaAr.push({ "fuzzy": { "cInterest": { "value": `${kw}`, "fuzziness": "AUTO" } } });
  });

  kibanaAr.push({
    "match": {
      "name": {
        "query": `${keyword}`,
        "fuzziness": "AUTO"
      }
    }
  });
  // kibanaAr.push({ "match": { "address": { "query": `${keyword}`, "fuzziness": 10} } });

  var stingified = JSON.stringify(kibanaAr)
  return escapeSpecialChars(stingified)
};

export const siteSearch = (keyword, userID) => {
  return `query MyQuery {
    ${searchPostsFuzzy(keyword, userID)}
    ${searchActivitiesFuzzySorted(keywordQueryActivities(keyword), userID, false, true)}
    ${searchUsersFuzzy(keywordQueryUsers(keyword))}
  }`;
};

export default siteSearch;
