import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../../AppContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  Skeleton,
} from "@mui/material";
import { Button } from "../basic/Button";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { SearchTextField } from "../basic/SearchTextField";
import Search from "@mui/icons-material/Search";
// import invoke from '../screens/script'
import PropTypes from "prop-types";
// import $ from 'jquery';
// import locationpickerInit from './locationpicker.jquery';

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

/**
 * Primary UI component for user interaction
 */
export const SelectLocationScreen = ({ ...props }) => {
  // Reference:
  // https://embed.plnkr.co/mfiPLrChUShIMLvpqjHI/

  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [scriptsLoaded, setScriptsLoaded] = React.useState(false);
  const [state, setState] = useState({
    location: p.submission.location,
  });

  const navigate = useNavigate();

  React.useEffect(() => {
    // List of script URLs
    const scriptUrls = [
      "https://code.jquery.com/jquery-1.10.2.min.js",
      `https://maps.google.com/maps/api/js?sensor=false&libraries=places&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
      "https://rawgit.com/Logicify/jquery-locationpicker-plugin/master/dist/locationpicker.jquery.js",
      `${process.env.REACT_APP_WEBSITE}/script.js?a=1`,
    ];

    // Function to load a script dynamically
    const loadScript = (index) => {
      if (index < scriptUrls.length) {
        const script = document.createElement("script");
        script.src = scriptUrls[index];
        script.async = true;

        // Event listener for script load
        script.onload = () => {
          // Load the next script in the sequence
          loadScript(index + 1);
        };

        // Event listener for script error
        script.onerror = () => {
          console.error(`Failed to load script: ${scriptUrls[index]}`);
        };

        document.body.appendChild(script);
      } else {
        // All scripts have been loaded
        setScriptsLoaded(true);
      }
    };

    // Check if scripts have already been loaded
    const scriptsAlreadyLoaded = scriptUrls.every((scriptUrl) => {
      return document.querySelector(`script[src="${scriptUrl}"]`);
    });

    if (!scriptsAlreadyLoaded) {
      // Start loading scripts sequentially
      loadScript(0);
      try {
        window.invoke();
      } catch (err) {
        console.log(err.messsage);
      }
    } else {
      // Set the state to indicate that scripts have been loaded
      setScriptsLoaded(true);
      try {
        window.invoke();
      } catch (err) {
        console.log(err.messsage);
      }
    }
  }, [window.invoke]); // Empty dependency array ensures that this effect runs only once on mount

  useEffect(() => {
    p.prevPage = p.currentPage || "select-location";
    p.currentPage = "select-location";
  }, []);

  var lat = useRef();
  var lng = useRef();
  var searchTextField = useRef();

  const setLocation = () => {
    p.submission = {
      ...p.submission,
      location: {
        ...p.submission?.location,
        q: searchTextField.value,
        longitude: lng.value,
        latitude: lat.value,
      },
    };

    setState({
      ...state,
      location: {
        ...state.location,
        q: searchTextField.value,
        longitude: lng.value,
        latitude: lat.value,
      },
    });
    if (p.prevPage === "create-activity") {
      navigate("/create-activity");
    } else if (p.prevPage === "edit-activity") {
      navigate(`/edit-activity/${p.editActivityID}`);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"}>
        <Box mb={4}>
          <ScreenNavHeader title="Choose the location">
            <Button onClick={setLocation} size={"large"}>
              Confirm
            </Button>
          </ScreenNavHeader>
        </Box>
        <Box mb={2.5}>
          <SearchTextField
            id="location"
            fullWidth={true}
            size="medium"
            placeholder="Search"
            sx={{ borderRadius: "100%" }}
            inputRef={(thisElem) => (searchTextField = thisElem)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="Search" edge="end">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Container>

      <Box style={{ "min-width": "100%" }}>
        <Box display={"none"}>
          Location:{" "}
          <input type="text" id="location-" style={{ width: "200px" }} />
          Lat{" "}
          <input
            type="text"
            id="lat"
            style={{ width: "200px" }}
            value={state.location?.latitude || 25.1941284}
            ref={(thisElem) => (lat = thisElem)}
          />
          Long:{" "}
          <input
            type="text"
            id="lng"
            style={{ width: "200px" }}
            value={state.location?.longitude || 55.2677267}
            ref={(thisElem) => (lng = thisElem)}
          />
        </Box>
        {window.invoke ? (
          <Box
            id="us2"
            sx={{
              "min-width": "100%",
              "min-height": "300px",
              height: { xs: "calc(100vh - 266px)", sm: "calc(100vh - 156px)" },
            }}
          ></Box>
        ) : (
          <Box>
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{
                height: {
                  xs: "calc(100vh - 266px)",
                  sm: "calc(100vh - 156px)",
                },
                width: "100%",
                transform: "none",
              }}
            />
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};

export const SelectLocationScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <SelectLocationScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

SelectLocationScreen.propTypes = {};

SelectLocationScreen.defaultProps = {
  color: "primary",
};
