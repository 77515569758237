import React, { useContext, useEffect, useRef, useState } from "react";
import { Link as ReactLink } from "react-router-dom";
import {
  Avatar,
  Box,
  Button as MuiButton,
  Container,
  List,
  ListItem,
  Skeleton,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import AppContext from "../../AppContext";
import { Chip } from "../basic/Chip.jsx";
import EventIcon from "@mui/icons-material/Event";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { AvatarLink } from "../basic/AvatarLink";
import { Button } from "../basic/Button";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import updateUserNotification from "./queries/updateUserNotification.js";
import getUserNotificationsByReceiverUser from "./queries/getUserNotificationsByReceiverUser.js";
import timestampdifference from "../utils/timestampdifference";
import { generateClient, post } from "aws-amplify/api";
const client = generateClient();

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

// Function to compare two items by date
function compareByDate(item1, item2) {
  const date1 = new Date(item1.createdAt);
  const date2 = new Date(item2.createdAt);

  // Compare the dates
  if (date1 > date2) {
    return -1;
  }
  if (date1 < date2) {
    return 1;
  }
  return 0;
}

const pathMap = {
  POST_TAG: "posts",
  POST_LIKE: "posts",
  POST_COMMENT: "comments",
  ACTIVITY_START: "activity",
  ACTIVITY_INVITE: "activity",
  ACTIVITY_JOIN: "activity",
  ACTIVITY_FINISHED: "activity",
  ACTIVITY_REQUEST: "activity",
  ACTIVITY_APPROVED: "activity",
  CHAT_MESSAGE: "chat",
};

// Make structure of PushNotification item similar
// to UserNotification so that items from both tables
// cab be used.
const flattenPushNotification = (notificationObject) => {
  // console.log("notificationObject.userObject", notificationObject.userObject)
  notificationObject = {
    ...notificationObject,
    ...notificationObject.data,
    userObject: { ...notificationObject.userObject },
    contacts: { ...notificationObject.userObject?.contacts },
    sortedUserIDSPK:
      notificationObject.userObject?.contacts?.items?.[0]?.sortedUserIDSPK,
    // activityObject: notificationObject.activityObject,
    // postObject: notificationObject.postObject
  };
  notificationObject.userObject = { ...notificationObject.info.sender };
  return notificationObject;
};

const createNotificationText = (notificationObject) => {
  const { activityObject, identity, userObject } = notificationObject;

  switch (identity) {
    case "POST_LIKE":
      return `${userObject?.givenName} ${userObject?.familyName} has liked your post`;
    case "POST_TAG":
      return `${userObject?.givenName} ${userObject?.familyName} has tagged you in a post`;
    case "POST_COMMENT":
      return `${userObject?.givenName} ${userObject?.familyName} has commented on your post`;
    case "ACTIVITY_START":
      return `${activityObject?.name} is starting`;
    case "ACTIVITY_FINISHED":
      return `${activityObject?.name} has ended`;
    case "ACTIVITY_REQUEST":
      return `${userObject?.givenName} ${userObject?.familyName} has requested to join your activity`;
    case "ACTIVITY_INVITE":
      return `${userObject?.givenName} ${userObject?.familyName} has invited you to an activity`;
    case "ACTIVITY_JOIN":
      return `${userObject?.givenName} ${userObject?.familyName} has joined your activity`;
    case "ACTIVITY_APPROVED":
      return `${userObject?.givenName} ${userObject?.familyName} has accepted your activity invite`;
    case "CLAN_INVITE":
      return `${userObject?.givenName} ${userObject?.familyName} has invited you to clan`;
    case "CONTACT_REQUEST":
      return `${userObject?.givenName} ${userObject?.familyName} has invited you to connect`;
    case "CHAT_MESSAGE":
      return `${userObject?.givenName} ${userObject?.familyName} has sent you a message`;
    default:
      return `A user has interacted with you. ${identity}`;
  }
};

const ChipCount = function (props) {
  return (
    <Box
      sx={{
        background: "white",
        borderRadius: "100%",
        display: "flex",
        width: 18,
        height: 18,
        ml: 1,
      }}
      justifyContent={"center"}
      alignContent={"center"}
      alignItems={"center"}
    >
      <Typography variant="caption">{props.count}</Typography>
    </Box>
  );
};

const NotificationButtonGroup = (props) => {
  const { notificationObject, keyValue, callback } = props;

  const [state, setState] = useState(null);

  const statusMap = {
    ACCEPTED: "accepted",
    CONFIRMED: "accepted",
    DECLINED: "declined",
  };

  var graphQLKeyAccept = "";
  var graphQLKeyDecline = "";

  const key = (() => {
    switch (keyValue) {
      case "CONTACT_REQUEST":
        graphQLKeyAccept = "ACCEPTED";
        return "contactObject";

      case "ACTIVITY_INVITE":
      case "ACTIVITY_REQUEST":
        graphQLKeyAccept = "CONFIRMED";
        return "activityAttendanceObject";
    }
  })();

  const word = (() => {
    switch (keyValue) {
      case "CONTACT_REQUEST":
        return "contact request";
      case "ACTIVITY_REQUEST":
        return "activity request";
      case "ACTIVITY_INVITE":
        return "activity invite";
    }
  })();

  if (state) {
    return (
      <Typography variant="body2" fontStyle={"italic"}>
        You {statusMap[state]} the {word}.
      </Typography>
    );
  } else {

    // If contact request
    if (keyValue === "CONTACT_REQUEST") {
      // console.log("notificationObject", notificationObject);

      // If user had accepted or declined other user's request
      if (
        notificationObject?.contacts?.items?.[0]?.status === "ACCEPTED" ||
        notificationObject?.contacts?.items?.[0]?.status === "DECLINED"
      ) {
        console.log("contact cond1");
        return (
          <Typography variant="body2" fontStyle={"italic"}>
            You {statusMap[notificationObject?.contacts?.items?.[0]?.status]} the{" "}
            {word}.
          </Typography>
        );
      }

      // If other user has requested to be connected. If the other user
      // has sent a request and not withdrawn it, the contact object will exist
      else if (
        notificationObject?.contacts?.items?.[0] &&
        notificationObject?.contacts?.items?.[0]?.status ===
          "REQUESTED"
      ) {
        console.log("contact cond2");
        return (
          <Stack mt={1} direction={"row"} spacing={0.5}>
            <Button
              notificationObject={notificationObject}
              onClick={() => {
                setState("ACCEPTED");
                callback(notificationObject, graphQLKeyAccept);
              }}
              color={
                notificationObject?.data?.info?.["contactObject"]?.[0]
                  ?.status === graphQLKeyAccept || state === graphQLKeyAccept
                  ? "primary"
                  : "secondary"
              }
              sx={{
                bgcolor:
                  notificationObject?.data?.info?.["contactObject"]?.[0]
                    ?.status === graphQLKeyAccept || state === "ACCEPTED"
                    ? "primary.dark"
                    : "secondary.dark",
              }}
              size="small"
            >
              Accept
            </Button>
            <Button
              color={
                notificationObject?.data?.info?.["contactObject"]?.[0]
                  ?.status === "DECLINED" || state === "DECLINED"
                  ? "primary"
                  : "secondary"
              }
              onClick={() => {
                setState("DECLINED");
                callback(notificationObject, "DECLINED");
              }}
              sx={{
                bgcolor:
                  notificationObject?.data?.info?.["contactObject"]?.[0]
                    ?.status === "DECLINED" || state === "DECLINED"
                    ? "primary.dark"
                    : "secondary.dark",
              }}
              size="small"
            >
              Decline
            </Button>
          </Stack>
        );
      }

      // If other user withdraw connection request or canceled connection
      else if (
        !notificationObject?.contacts?.items?.[0] ||
        notificationObject?.contacts?.items?.[0]?.length === 0
      ) {
        //console.log("contact cond3");
        return (
          <Typography variant="body2" fontStyle={"italic"}>
            The invitation has expired.
          </Typography>
        );
      }
    }

    // Otherwise
    else {
      // console.log("notificationObject---------", notificationObject);

      // If user had accepted or declined other user's invitation
      if (
        notificationObject?.activityAttendanceObject?.status === "CONFIRMED" ||
        notificationObject?.activityAttendanceObject?.status === "DECLINED" ||
        notificationObject?.activityAttendanceObject?.status === "UNCONFIRMED"
      ) {
        console.log("activity cond1");
        return (
          <Typography variant="body2" fontStyle={"italic"}>
            You {statusMap[notificationObject?.["activityAttendanceObject"]?.status]} the{" "}
            {word}.
          </Typography>
        );
      }

      // If other user has invited to activity. If the other user
      // has sent an invitiation and not withdrawn it, the activity object will exist
      else if (
        notificationObject.activityObject
      ) {
        console.log("activity cond2");
        return (
          <Stack mt={1} direction={"row"} spacing={0.5}>
            <Button
              notificationObject={notificationObject}
              onClick={() => {
                setState("ACCEPTED");
                callback(notificationObject, graphQLKeyAccept);
              }}
              color={
                notificationObject?.data?.info?.["activityObject"]?.[0]
                  ?.status === graphQLKeyAccept || state === graphQLKeyAccept
                  ? "primary"
                  : "secondary"
              }
              sx={{
                bgcolor:
                  notificationObject?.data?.info?.["activityObject"]?.[0]
                    ?.status === graphQLKeyAccept || state === "ACCEPTED"
                    ? "primary.dark"
                    : "secondary.dark",
              }}
              size="small"
            >
              Accept
            </Button>
            <Button
              color={
                notificationObject?.data?.info?.["activityObject"]?.[0]
                  ?.status === "DECLINED" || state === "DECLINED"
                  ? "primary"
                  : "secondary"
              }
              onClick={() => {
                setState("DECLINED");
                callback(notificationObject, "DECLINED");
              }}
              sx={{
                bgcolor:
                  notificationObject?.data?.info?.["activityObject"]?.[0]
                    ?.status === "DECLINED" || state === "DECLINED"
                    ? "primary.dark"
                    : "secondary.dark",
              }}
              size="small"
            >
              Decline
            </Button>
          </Stack>
        );
      }

      // If other user withdraw activity request or canceled activity
      else if (
        !notificationObject.activityObject ||
        notificationObject?.data?.info?.["activityObject"]?.length === 0
      ) {
        console.log("activity cond3");
        return (
          <Typography variant="body2" fontStyle={"italic"}>
            The invitation has expired.
          </Typography>
        );
      }
    }
  }
};

/**
 * Primary UI component for user interaction
 */
export const NotificationsScreen = ({ ...props }) => {
  const [
    globalState,
    setGlobalState,
    q,
    p,
    connectionStatus,
    isBackgroundLoaded,
    notificationsGlobalState,
    setNotificationsGlobalState,
  ] = useContext(AppContext);
  const [state, setState] = useState({
    data: [],
    nextToken: null,
    loading: true,
    filter: "All",
  });

  const nowDateTime = new Date();
  const notificationItems = useRef();

  const fetchData = async () => {
    var apiKey = "da2-22ztnhuamje4jptwonwzfafdi4"; // Replace 'xxx' with your actual API key

    await fetch(
      "https://3arkzxpkarhoflbprgz4tpruai.appsync-api.us-east-1.amazonaws.com/graphql",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify({
          query: getUserNotificationsByReceiverUser(p?.userData?.userId),
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        // For activity invitations, contact invitiations, etc.
        const typeCount = {};
        res.data.getUserNotificationsByReceiverUser.items.forEach(
          (objString, i) => {
            var obj = {...objString};

            // console.log("obj", obj)
            obj.data.info = JSON.parse(obj.data.info);

            typeCount[obj.data.identity] = typeCount[obj.data.identity]
              ? // Increment if element counted before
                typeCount[obj.data.identity] + 1
              : // Or add 1 for first occurance
                1;

            if (
              typeof res.data.getUserNotificationsByReceiverUser.items[i].data
                .info === "string"
            ) {
              res.data.getUserNotificationsByReceiverUser.items[i].data.info =
                JSON.parse(obj.data.info);
            }
          }
        );
        notificationItems.current =
          res.data.getUserNotificationsByReceiverUser.items;

        setState({
          ...state,
          data: [...res.data.getUserNotificationsByReceiverUser.items],
          typeCount,
          loading: false,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const updateToSeen = async () => {
    if (!notificationItems.current) return;

    var apiKey = "da2-22ztnhuamje4jptwonwzfafdi4"; // Replace 'xxx' with your actual API key

    // Localy update state of messages
    setNotificationsGlobalState({
      ...notificationsGlobalState,
      newNotifications: [
        ...notificationItems.current.map((obj) => ({
          ...obj,
          status: "SEEN",
        })),
      ],
    });

    await fetch(
      "https://3arkzxpkarhoflbprgz4tpruai.appsync-api.us-east-1.amazonaws.com/graphql",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify({
          query: updateUserNotification(notificationItems.current),
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        // setNotificationsGlobalState({
        //   ...notificationsGlobalState,
        //   newNotifications: [
        //     ...notificationItems.current.map((obj) => ({
        //       ...obj,
        //       status: "SEEN",
        //     })),
        //   ],
        // });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const onFilterHandler = (event) => {
    const filterName = event.currentTarget.getAttribute("label");
    setState({
      ...state,
      filter: filterName,
    });
  };

  const onUpdateConnectionStatus = async (notificationObject, status) => {
    if (!notificationObject.sortedUserIDSPK) return;

    const sortedUserIDSPK = notificationObject.sortedUserIDSPK;

    q.updateState(globalState);
    const res = await q.doQuery(
      "updateConnectionStatus",
      status === "ACCEPTED"
        ? "Connection request accepted"
        : "Connection request declined",
      "An error occured",
      {},
      p,
      sortedUserIDSPK,
      status
    );
  };

  const onUpdateJoinRequest = async (notificationObject, status) => {
    // console.log("notificationObject", notificationObject);
    q.updateState(globalState);
    const res = await q.doQuery(
      "updateActivityAttendanceStatus",
      status === "CONFIRMED"
        ? "Join request accepted"
        : "Join request declined",
      "An error occured",
      {},
      p,
      notificationObject.info?.activityAttendanceID,
      status
    );
    // console.log('res', res)
  };

  useEffect(() => {
    if (!globalState?.userData?.userId) {
      return;
    }
    (async () => {
      await fetchData();
      console.log(1);
      updateToSeen();
      console.log(2);
    })();
  }, [
    globalState?.userData,
    // notificationsGlobalState?.newNotifications,
  ]);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"}>
        <Box>
          <Box mb={3}>
            <ScreenNavHeader title="Notifications" />
          </Box>

          <Box mb={4} sx={{ overflowX: "scroll" }}>
            <Stack
              pb={3}
              sx={{ width: "fit-content" }}
              direction={"row"}
              spacing={1}
            >
              {[
                [
                  "All",
                  state.data.filter(
                    (obj) => obj.data.identity !== "CHAT_MESSAGE"
                  ).length,
                ],
                [
                  "Activities",
                  (state?.typeCount?.["ACTIVITY_JOIN"] || 0) +
                    (state?.typeCount?.["ACTIVITY_REQUEST"] || 0) +
                    (state?.typeCount?.["ACTIVITY_INVITE"] || 0) +
                    (state?.typeCount?.["ACTIVITY_START"] || 0) +
                    (state?.typeCount?.["ACTIVITY_FINISH"] || 0) || 0,
                  EventIcon,
                ],
                [
                  "Reactions",
                  (state?.typeCount?.["POST_LIKE"] || 0) +
                    (state?.typeCount?.["POST_COMMENT"] || 0) +
                    (state?.typeCount?.["POST_TAG"] || 0) || 0,
                ],
                ["Contacts", state?.typeCount?.["CONTACT_REQUEST"] || 0],
              ].map((filterArr) => (
                <Button
                  size="small"
                  fullWidth
                  color={"secondary"}
                  label={filterArr[0]}
                  onClick={onFilterHandler}
                  startIcon={filterArr?.[2] && EventIcon}
                  sx={{
                    background:
                      state.filter === filterArr[0] ? "#8EC8E9" : "#f3f4f5",
                    minWidth: "fit-content",
                    "&:hover": {
                      background:
                        state.filter === filterArr[0] ? "#8EC8E9" : "#f3f4f5",
                    },
                  }}
                >
                  {filterArr[0]}
                  <ChipCount count={filterArr[1]} />
                </Button>
              ))}
            </Stack>
          </Box>

          <Box>
            <List sx={{ m: 0, p: 0, listStyle: "none" }}>
              {state.loading &&
                // state?.data?.length === 0 &&
                [1, 2, 3, 4].map((v, i) => (
                  <Stack
                    key={`skeleton-activity-${i}`}
                    direction={"column"}
                    spacing={1}
                    mb={4}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      spacing={2}
                      sx={{ width: "100%" }}
                    >
                      <Skeleton
                        animation={"wave"}
                        variant="circular"
                        width={48}
                        height={48}
                      />
                      <Stack
                        direction={"row"}
                        spacing={1}
                        flexGrow={1}
                        justifyContent={"space-between"}
                      >
                        <Stack direction={"column"} spacing={1}>
                          <Skeleton variant="rounded" width={160} height={10} />
                          <Skeleton
                            variant="rounded"
                            width={100}
                            height={10}
                            sx={{ flexGrow: 1 }}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                ))}

              {!state.loading &&
                state.data
                  .filter((notificationObject) => {
                    notificationObject =
                      flattenPushNotification(notificationObject);
                    if (state.filter === "All") {
                      if (notificationObject.identity !== "CHAT_MESSAGE") {
                        return true;
                      }
                    } else if (state.filter === "Updates") {
                      if (
                        notificationObject.identity === "ACTIVITY_START" ||
                        notificationObject.identity === "ACTIVITY_FINISH"
                      ) {
                        return true;
                      }
                    } else if (
                      state.filter === "Contacts" &&
                      notificationObject.identity === "CONTACT_REQUEST" &&
                      notificationObject["contactObject"]?.status
                    ) {
                      return true;
                    } else if (
                      state.filter === "Activities" &&
                      (notificationObject.identity === "ACTIVITY_JOIN" ||
                        notificationObject.identity === "ACTIVITY_REQUEST" ||
                        notificationObject.identity === "ACTIVITY_START" ||
                        notificationObject.identity === "ACTIVITY_FINISH" ||
                        notificationObject.identity === "ACTIVITY_INVITE")
                    ) {
                      return true;
                    } else if (
                      state.filter === "Reactions" &&
                      (notificationObject.identity === "POST_LIKE" ||
                        notificationObject.identity === "POST_TAG" ||
                        notificationObject.identity === "POST_COMMENT")
                    ) {
                      return true;
                    } else {
                      return notificationObject.identity === state.filter;
                    }
                  })
                  .sort(compareByDate)
                  .map((notificationObject, i) => {
                    notificationObject =
                      flattenPushNotification(notificationObject);

                    return (
                      <ListItem disablePadding>
                        <Stack
                          key={`notification-${i}`}
                          direction={"row"}
                          spacing={2}
                          alignItems={"flex-start"}
                          mb={4}
                          sx={{ width: "100%" }}
                        >
                          <AvatarLink
                            to={`/profile/${notificationObject?.userObject?.id}`}
                            sx={{ width: 48, height: 48 }}
                            src={
                              notificationObject?.identity?.substring(0, 4) ===
                              "POST"
                                ? notificationObject?.userObject?.image?.url ||
                                  notificationObject?.userObject?.images
                                    ?.items?.[0]?.url
                                : notificationObject?.userObject?.image?.url ||
                                  `${process.env.REACT_APP_WEBSITE}/logo-color.png`
                            }
                          />

                          <Stack direction={"column"} sx={{ flexGrow: 1 }}>
                            <Typography variant="body1">
                              {notificationObject?.pushNotificationBody}
                            </Typography>

                            <Stack
                              direction="row"
                              justifyContent={"space-between"}
                              sx={{ width: "100%" }}
                            >
                              <Typography variant="body1">
                                {createNotificationText(notificationObject)}
                              </Typography>
                              <Typography variant="caption" textAlign={"right"}>
                                {timestampdifference(
                                  nowDateTime,
                                  notificationObject.createdAt
                                )}
                              </Typography>
                            </Stack>

                            <ReactLink
                              to={`/${pathMap[notificationObject?.identity]}/${
                                notificationObject?.postObject?.id
                              }`}
                            >
                              <Typography variant="body1">
                                {notificationObject?.postObject?.text}
                              </Typography>
                            </ReactLink>

                            <ReactLink
                              style={{ textDecoration: "none" }}
                              to={`/${
                                pathMap[notificationObject?.identity]
                              }/${notificationObject?.sortedUserIDSPK?.replace(
                                "#",
                                ","
                              )}`}
                            >
                              <Typography sx={{ ml: -0.5 }} variant="body2">
                                {notificationObject?.chatObject?.content
                                  ?.text && (
                                  <>
                                    &#x301D;{" "}
                                    {
                                      notificationObject?.chatObject?.content
                                        ?.text
                                    }{" "}
                                    &#x301E;
                                  </>
                                )}
                              </Typography>
                            </ReactLink>
                            <ReactLink
                              to={`/${pathMap[notificationObject?.identity]}/${
                                notificationObject?.activityObject?.id
                              }`}
                            >
                              <Typography variant="body1" fontWeight={700}>
                                {notificationObject?.activityObject?.name}
                              </Typography>
                            </ReactLink>
                            {notificationObject.identity ===
                              "CONTACT_REQUEST" && (
                              <NotificationButtonGroup
                                keyValue="CONTACT_REQUEST"
                                notificationObject={notificationObject}
                                callback={onUpdateConnectionStatus}
                              />
                            )}

                            {notificationObject.identity ===
                              "ACTIVITY_REQUEST" && (
                              <NotificationButtonGroup
                                keyValue="ACTIVITY_REQUEST"
                                notificationObject={notificationObject}
                                callback={onUpdateJoinRequest}
                              />
                            )}

                            {notificationObject.identity ===
                              "ACTIVITY_INVITE" && (
                              <NotificationButtonGroup
                                keyValue="ACTIVITY_INVITE"
                                notificationObject={notificationObject}
                                callback={onUpdateJoinRequest}
                              />
                            )}
                          </Stack>
                        </Stack>
                      </ListItem>
                    );
                  })}
            </List>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export const NotificationsScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <NotificationsScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

NotificationsScreen.propTypes = {};

NotificationsScreen.defaultProps = {
  color: "primary",
};
