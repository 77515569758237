const getUserNotificationsByReceiverUser = (receiverID) => `query MyQuery {
  getUserNotificationsByReceiverUser(
    receiverUserID: "${receiverID}"
    sortDirection: DESC
  ) {
    items {
      id
      status
      createdAt
      userObject {
        id
        givenName
        familyName
        images(limit: 1) {
          items {
            url
          }
        }
        contacts(filter: {contactID: {eq: "${receiverID}"}}) {
          items {
            id
            status
            sortedUserIDSPK
          }
        }
      }
      data {
        path
        note
        info
        identity
        url
      }
      activityObject{
        id
        name
        createdAt
      }
      postObject {
        id
        createdAt
        activityID
        text
      }
      chatObject {
        id
        channelIDCreatedByIDPK
        channelID
        channel {
          sortedP2PUserIDSPK
        }
        createdAt
        type
        content {
          text
          data
        }
      }
      contactObject {
        id
        status
        sortedUserIDSPK
      }
      activityAttendanceObject {
        user {
          familyName
          givenName
        }
        status
      }
      receiverUserID
    }
  }
}`;


export default getUserNotificationsByReceiverUser;
