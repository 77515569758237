import React, {
  forwardRef,
  useImperativeHandle,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { VariableSizeList as List } from "react-window";
import AppContext from "../../AppContext";
// import Box from '@mui/material/Box';
import { Button } from "../basic/Button";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { ActivityPostCard } from "../complex/ActivityPostCard";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import "./HomeScreen.css";
import timestampdifference from "../utils/timestampdifference";
import { generateClient, post } from "aws-amplify/api";
import listPostsByStatus from "./queries/listPostsByStatus.js";
import AutoSizer from "react-virtualized-auto-sizer";

const client = generateClient();

const theme = createTheme({
  palette: {
    primary: {
      main: "#2D9CDB",
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#E0C2FF",
      light: "#F5EBFF",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#47008F",
    },
  },
  typography: {
    fontFamily: [
      '"Lato"',
      "Roboto",
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

const ResponsiveBoxContainer = styled(Box)(({ theme }) => ({
  background: "solid 1px rgba(0,0,0,0.15)",
  margin: "0 auto",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    //background: "#f6f7f9",
  },
}));

const ResponsiveStack = styled(Stack)(({ theme }) => ({
  //maxWidth: 530,
  // marginLeft: 'auto',
  // marginRight: 'auto',
  // borderBottom: 'solid 1px rgba(0,0,0,0.15)',
  background: "white",
  [theme.breakpoints.down("md")]: {
    paddingLeft: 16,
    paddingRight: 16,
    borderBottom: 0,
    marginBottom: 8,
  },
  "&:hover": {
    cursor: "pointer",
    // background: 'rgba(0,0,0,0.025)'
  },
}));

const ResponsiveDivider = styled(Divider)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    //display: "none",
  },
}));

const HomeScreen = forwardRef(function HomeScreen(props, ref) {
  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [state, setState] = useState({
    data: [],
    feedData: p.feedData || [],
    nextToken: null,
    nextTokenFeed: p.nextTokenFeed || null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNew, setIsLoadingNew] = useState(false);
  const [page, setPage] = useState(0);
  const [scrollOffset, setScrollOffset] = useState(p.homeFeedScrollOffset || 0);
  const [listKey, setListKey] = useState(0);

  const listRef = useRef(null); // Ref for List component
  const homeFeedContainer = useRef(null);

  useImperativeHandle(ref, () => ({
    scrollToTop: () => {
      if (listRef.current) {
        listRef.current.scrollTo(0);
      }
    },
    // You can add other methods here if needed
  }));

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await client.graphql({
        query: listPostsByStatus(
          p.userData?.userId,
          p.nextTokenFeed ? `, nextToken: "${p.nextTokenFeed}"` : ""
        ),
        fetchPolicy: "network-only",
      });

      if (res.errors) {
        console.error("GraphQL errors:", res.errors);
        alert(
          "There was an issue retrieving the data. Please try again later."
        );
        return;
      }

      p.feedData = p.feedData
        ? [...p.feedData, ...res.data.listPostsByStatus.items]
        : [...res.data.listPostsByStatus.items];

      p.nextTokenFeed = res.data.listPostsByStatus.nextToken;

      setState({
        ...state,
        ...p,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      p.homeScreenFetchLock = 0;
      setIsLoading(false);
    }
  };

  const fetchNewData = async () => {
    try {
      setIsLoadingNew(true);
      const res = await client.graphql({
        query: listPostsByStatus(
          p.userData?.userId,
          "",
          "",
          p.feedData?.[0].createdAt
        ),
        fetchPolicy: "network-only",
      });

      if (res.errors) {
        console.error("GraphQL errors:", res.errors);
        alert(
          "There was an issue retrieving the data. Please try again later."
        );
        return;
      }

      const newItems = res.data.listPostsByStatus.items || [];
      const existingIds = new Set(p.feedData?.map((item) => item.id) || []);
      const uniqueNewItems = newItems.filter(
        (item) => !existingIds.has(item.id)
      );
      p.feedData = uniqueNewItems.concat(p.feedData || []);

      p.nextTokenFeed = res.data.listPostsByStatus.nextToken;

      listRef.current.scrollTo(0);

      setState({
        ...state,
        ...p,
      });
      setIsLoadingNew(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoadingNew(false);
    } finally {
      p.homeScreenFetchLock = 0;
      setIsLoadingNew(false);
    }
  };

  const showNewPosts = () => {
    fetchNewData();
    homeFeedContainer.current.scrollIntoView();
    setGlobalState({ ...globalState, showNewPostsButton: false });
  };

  const isInitialMount = useRef(true);

  const handleScroll = ({ scrollOffset, scrollDirection }) => {
    setScrollOffset(scrollOffset);
    p.homeFeedScrollOffset = scrollOffset;

    if (scrollDirection === "forward" && !isLoading) {
      const listElement = listRef.current;
      if (listElement) {
        const { clientHeight, scrollHeight, scrollTop } = listElement._outerRef;
        if (scrollHeight - scrollTop - clientHeight < 100) {
          // 100px threshold
          fetchData();
        }
      }
    }
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (listRef.current) {
        listRef.current.scrollTo(scrollOffset);
      }
    }
  }, [scrollOffset]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTo(p.homeFeedScrollOffset);
    }
  }, [p.homeFeedScrollOffset]);

  // const handleScroll = () => {
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop >=
  //     document.documentElement.offsetHeight / 1.2
  //   ) {
  //     setPage((prevPage) => prevPage + 1);
  //   }
  // };

  useEffect(() => {
    (async () => {
      p.prevPage = p.currentPage || "home";
      p.currentPage = "home";
      p.journey = "init";
      p.journey = "home";

      p.homeScreenFetchLock = 1;

      if (!p.feedData || p.feedData.length === 0) {
        fetchData();
      }
      // window.addEventListener("scroll", handleScroll);

      // return () => {
      //   window.removeEventListener("scroll", handleScroll);
      // };
    })();
  }, []);

  const memoizedGetRowHeight = useMemo(() => {
    return (index) => {
      if (state.feedData?.[index]?.images?.items?.length > 0) {
        return 522;
      } else {
        return 156;
      }
    };
  }, [state.feedData]);

  const itemKey = useCallback(
    (index, data) => {
      return state.feedData[index]?.id || index;
    },
    [state.feedData]
  );

  useEffect(() => {
    setListKey((prevKey) => prevKey + 1);
  }, [state.feedData]);

  useEffect(() => {
    (async () => {
      if (p.homeScreenLoadedInit !== true) {
        if (!p?.userData?.userId) {
          return;
        } else {
          if (p.homeScreenFetchLock === 0) {
            p.homeScreenFetchLock = 1;
            fetchData(page);
          }
        }
      }
    })();
  }, [page]);

  const Row = ({ index, style }) => {
    const itemObj = state.feedData?.[index];
    if (!itemObj) return null;

    const {
      id,
      userObject,
      text,
      createdAt,
      images,
      commentsCount,
      reactionsCount,
      reactions,
    } = itemObj;

    const userAvatar =
      userObject?.images?.items?.[0]?.url || "/path/to/default-avatar.jpg";

    return (
      <div style={style}>
        <ResponsiveStack key={`feedcard-${index}`} pt={2} px={4}>
          <ActivityPostCard
            id={id}
            currentUserObject={state?.userData}
            userObject={userObject}
            userAvatar={userAvatar}
            userName={`${userObject?.givenName} ${userObject?.familyName}`}
            userId={userObject?.id}
            image={images?.items?.[0]?.url}
            imagesData={images?.items}
            text={text}
            commentsCount={commentsCount}
            reactionsCount={reactionsCount}
            likeReactionID={reactions?.items?.[0]?.id}
            liked={reactions?.items?.[0]?.type === "HEART"}
            createdAt={createdAt}
          />
        </ResponsiveStack>
        <ResponsiveDivider />
      </div>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <ResponsiveBoxContainer
        ref={(thisElem) => (homeFeedContainer.current = thisElem)}
      >
        <div>
          {(!state.feedData || state.feedData?.length === 0) &&
            [1, 2, 3].map((v, i) => (
              <ResponsiveStack
                sx={{ "&:hover": { background: "none" } }}
                key={`skeleton-activity-${i}`}
                direction={"column"}
                spacing={1}
                mb={0}
                py={2}
                px={4}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={1}
                  sx={{ width: "100%" }}
                >
                  <Skeleton
                    animation={"wave"}
                    variant="circular"
                    width={48}
                    height={48}
                  />
                  <Stack
                    direction={"row"}
                    spacing={1}
                    flexGrow={1}
                    justifyContent={"space-between"}
                  >
                    <Stack direction={"column"} spacing={1}>
                      <Skeleton variant="rounded" width={160} height={10} />
                      <Skeleton
                        variant="rounded"
                        width={100}
                        height={10}
                        sx={{ flexGrow: 1 }}
                      />
                    </Stack>
                  </Stack>
                </Stack>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={"100%"}
                  height={170}
                />
              </ResponsiveStack>
            ))}

          <Stack
            sx={{
              position: { xs: "fixed", sm: "fixed" },
              zIndex: "20000",
              top: globalState?.showNewPostsButton ? 32 : -48,
              width: "100%",
              transition: "all 0.25s",
            }}
            alignItems={"center"}
            maxWidth={"sm"}
          >
            <Button
              onClick={showNewPosts}
              sx={{
                width: 120,
                boxShadow: "0px 1px 5px rgba(0,0,0,0.5)",
              }}
            >
              New Posts
            </Button>
          </Stack>

          {isLoadingNew && (
            <Stack sx={{ width: "100%" }} justifyContent={"center"} py={2}>
              <CircularProgress sx={{ margin: "0 auto" }} />
            </Stack>
          )}

          {state.feedData?.length > 0 && (
            <List
              key={listKey}
              ref={(thisElem) => {
                listRef.current = thisElem;
                p.listRef = thisElem;
              }}
              height={window.innerHeight}
              itemSize={memoizedGetRowHeight}
              itemCount={state.feedData?.length}
              width={"100%"}
              onScroll={handleScroll}
              initialScrollOffset={p.homeFeedScrollOffset}
              itemKey={itemKey}
            >
              {Row}
            </List>
          )}

          {isLoading && (
            <Stack sx={{ width: "100%" }} justifyContent={"center"} py={2}>
              <CircularProgress sx={{ margin: "0 auto" }} />
            </Stack>
          )}
        </div>
      </ResponsiveBoxContainer>
    </ThemeProvider>
  );
});

export const HomeScreenLayout = forwardRef((props, ref) => {
  return (
    <ResponsiveLayoutSidebar
      pt={{ xs: 8, sm: 0 }}
      pb={{ xs: 8, sm: 0 }}
      bg={"none"}
    >
      <HomeScreen ref={ref} {...props} />
    </ResponsiveLayoutSidebar>
  );
});
