/**
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0.
 */
// import { useEffect } from "react";
import { mqtt5, auth, iot } from "aws-iot-device-sdk-v2";
import { once } from "events";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { toUtf8 } from "@aws-sdk/util-utf8-browser";

// @ts-ignore
import {
  AWS_REGION,
  AWS_COGNITO_IDENTITY_POOL_ID,
  AWS_IOT_ENDPOINT,
} from "./pubsub-settings";
import jquery from "jquery";
const $ = jquery;

function log(msg) {
  let now = new Date();
  // $("#message").append(`<pre>${now.toString()}: ${msg}</pre>`)
  //console.log("PUBSUB", msg);
}

/**
 * AWSCognitoCredentialsProvider. The AWSCognitoCredentialsProvider implements AWS.CognitoIdentityCredentials.
 *
 */

class AWSCognitoCredentialsProvider extends auth.CredentialsProvider {
  constructor(options, expire_interval_in_ms) {
    super();
    this.options = options;

    setInterval(async () => {
      await this.refreshCredentials();
    }, expire_interval_in_ms ?? 3600 * 1000);
  }

  getCredentials() {
    return {
      aws_access_id: this.cachedCredentials?.accessKeyId ?? "",
      aws_secret_key: this.cachedCredentials?.secretAccessKey ?? "",
      aws_sts_token: this.cachedCredentials?.sessionToken,
      aws_region: this.options.Region,
    };
  }

  async refreshCredentials() {
    //log("Fetching Cognito credentials")
    this.cachedCredentials = await fromCognitoIdentityPool({
      // Required. The unique identifier for the identity pool from which an identity should be
      // retrieved or generated.
      identityPoolId: this.options.IdentityPoolId,
      clientConfig: { region: this.options.Region },
    })();
  }
}

class MQTTClientManager {
  constructor(
    AWS_COGNITO_IDENTITY_POOL_ID,
    AWS_REGION,
    props,
    qos1Topic,
    user_msg_count,
    maxRetries = 5 // Maximum number of retries
  ) {
    this.AWS_COGNITO_IDENTITY_POOL_ID = AWS_COGNITO_IDENTITY_POOL_ID;
    this.AWS_REGION = AWS_REGION;
    this.props = props;
    this.qos1Topic = qos1Topic;
    this.user_msg_count = user_msg_count;
    this.maxRetries = maxRetries;
    this.client = null;
    this.retryCount = 0;
  }

  async initialize() {
    const provider = new AWSCognitoCredentialsProvider({
      IdentityPoolId: this.AWS_COGNITO_IDENTITY_POOL_ID,
      Region: this.AWS_REGION,
    });
    await provider.refreshCredentials();

    this.client = this.createClient(provider, this.props.callback);
    await this.connectWithRetry();

    const suback = await this.client.subscribe({
      subscriptions: [
        { qos: mqtt5.QoS.AtLeastOnce, topicFilter: this.qos1Topic },
      ],
    });
    log("Suback result: " + JSON.stringify(suback));

    const qos1PublishResult = await this.client.publish({
      qos: mqtt5.QoS.AtLeastOnce,
      topicName: this.qos1Topic,
      payload: JSON.stringify({ message: "IOT Core is connected" }),
    });
    log("QoS 1 Publish result: " + JSON.stringify(qos1PublishResult));
  }

  async connectWithRetry() {
    while (this.retryCount < this.maxRetries) {
      try {
        const attemptingConnect = once(this.client, "attemptingConnect");
        const connectionSuccess = once(this.client, "connectionSuccess");
        this.client.start();

        await attemptingConnect;
        await connectionSuccess;

        this.retryCount = 0; // Reset retry count on successful connection
        log("Successfully connected.");
        break;
      } catch (error) {
        this.retryCount++;
        log(`Connection attempt ${this.retryCount} failed: ${error}`);

        if (this.retryCount >= this.maxRetries) {
          log("Maximum retry attempts reached. Giving up.");
          throw error;
        }

        const retryDelay = this.getExponentialBackoffDelay(this.retryCount);
        log(`Retrying in ${retryDelay}ms...`);
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
      }
    }
  }

  getExponentialBackoffDelay(retryCount) {
    const baseDelay = 1000; // 1 second base delay
    const jitter = Math.random() * 100; // Add some jitter
    return baseDelay * Math.pow(2, retryCount) + jitter;
  }

  async publishMessage(message, recipientTopic) {
    try {
      const publishResult = await this.client.publish({
        qos: mqtt5.QoS.AtLeastOnce,
        topicName: recipientTopic,
        payload: message,
      });
      this.user_msg_count++;
    } catch (error) {
      log(`Error publishing: ${error}`);
    }
  }

  async closeConnection() {
    const disconnection = once(this.client, "disconnection");
    const stopped = once(this.client, "stopped");

    this.client.stop();

    await disconnection;
    await stopped;
  }

  createClient(provider, callback) {
    let wsConfig = {
      credentialsProvider: provider,
      region: this.AWS_REGION,
    };

    let builder =
      iot.AwsIotMqtt5ClientConfigBuilder.newWebsocketMqttBuilderWithSigv4Auth(
        AWS_IOT_ENDPOINT,
        wsConfig
      );

    let client = new mqtt5.Mqtt5Client(builder.build());

    client.on("error", (error) => {
      log("Error event: " + error.toString());
    });

    client.on("messageReceived", (eventData) => {
      if (JSON.stringify(eventData.message.payload)) {
       // console.log("  with payload: " + toUtf8(eventData.message.payload));

        if (
          JSON.parse(toUtf8(eventData.message.payload)).message ===
          "IOT Core is connected"
        ) {
          return;
        }

        if (this.props.callback) {
          this.props.callback(JSON.parse(toUtf8(eventData.message.payload)));
        }
        log(
          `message with payload ${JSON.parse(
            toUtf8(eventData.message.payload)
          )} `
        );
      }
    });

    client.on("attemptingConnect", (eventData) => {
      log("Attempting Connect event");
    });

    client.on("connectionSuccess", (eventData) => {
      log("Connection Success event");
      log("Connack: " + JSON.stringify(eventData.connack));
      log("Settings: " + JSON.stringify(eventData.settings));
    });

    client.on("connectionFailure", (eventData) => {
      log("Connection failure event: " + eventData.error.toString());
    });

    client.on("disconnection", (eventData) => {
      log("Disconnection event: " + eventData.error.toString());
      if (eventData.disconnect !== undefined) {
        log("Disconnect packet: " + JSON.stringify(eventData.disconnect));
      }
    });

    client.on("stopped", (eventData) => {
      log("Stopped event");
    });

    return client;
  }
}


// Usage:
// const mqttManager = new MQTTClientManager(
//   AWS_COGNITO_IDENTITY_POOL_ID,
//   AWS_REGION,
//   props,
//   qos1Topic,
//   user_msg_count
// );
// mqttManager.initialize();
// mqttManager.testSuccessfulConnection();
// mqttManager.publishMessage();
// mqttManager.closeConnection();

export default MQTTClientManager;
