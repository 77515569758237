import React, { useContext, useEffect, useRef, useState } from "react";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import AppContext from "../../AppContext.js";
import {
  Avatar,
  Box,
  ButtonGroup,
  CircularProgress,
  Container,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "../basic/Button";
import { Chip } from "../basic/Chip.jsx";
import { TextField } from "../basic/TextField";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import { ResponsiveToaster } from "../basic/ResponsiveToaster.jsx";
import { styled } from "@mui/system";
import { generateClient, post } from "aws-amplify/api";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import useMediaQuery from "@mui/material/useMediaQuery";
import listInvites from "./queries/listInvites.js";

const client = generateClient();

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

const ButtonGroupStyled = styled(ButtonGroup)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  boxShadow: "none",
  display: "flex",
  width: "100%",
  [theme.breakpoints.up("md")]: {},
  [theme.breakpoints.down("md")]: {
    // marginTop: '30.5%',
    alignItems: "center",
    flexGrow: 0, //0.5
  },
}));

const FlexGrowBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    flexGrow: 0, //0.5
  },
}));

const GroupButton = styled(Button)(
  ({ theme }) => `
  height: 56px;
`
);

const GroupTextField = styled(TextField)(
  ({ theme }) => `
    &:hover > div.MuiOutlinedInput-root > fieldset.MuiOutlinedInput-notchedOutline,
    & .Mui-focused > fieldset.MuiOutlinedInput-notchedOutline,
    & > div.MuiOutlinedInput-root > fieldset.MuiOutlinedInput-notchedOutline {
      top: 0px;
      height: 52px;
      border-left: none !important;
    }
    & fieldset > legend {
      display: none;
    }
    & > .MuiInputBase-root > fieldset.MuiOutlinedInput-notchedOutline {
      border-color:  #1B1B1E;
    }
    &:hover > .MuiInputBase-root > fieldset.MuiOutlinedInput-notchedOutline {
      border-color:  #1B1B1E;
    }
    & .Mui-focused > fieldset.MuiOutlinedInput-notchedOutline {
      border-color: #1B1B1E !important;
    }
    & > div {
      border-left: none;
      border-radius: 0px 45px 45px 0px !important; 
    }
`
);

export default function BasicButtonGroup(props) {
  return (
    <ButtonGroupStyled
      {...props.sx}
      {...props.flexGrow}
      variant="contained"
      aria-label="Basic button group"
    >
      <GroupButton
        color="secondary"
        component={ReactLink}
        to="/contacts/add/phone"
        sx={{
          border: "solid 2px #1B1B1E !important",
          paddingTop: 1,
          paddingBottom: 1,
          paddingLeft: 2,
          paddingRight: 1,
          width: 140,
        }}
      >
        <span
          style={{ marginRight: 8, fontSize: 20 }}
          className={`fi fi-${
            props?.authFlow?.countryObj?.code.toLowerCase() || "ae"
          }`}
        ></span>
        <span style={{ width: 90 }}>
          {props?.authFlow?.countryObj?.dial_code}
        </span>
      </GroupButton>

      <GroupTextField
        label=""
        type={useMediaQuery(theme.breakpoints.up("sm")) ? "text" : "number"}
        onChange={props.onChange}
        sx={{ width: "100%", height: 56 }}
        value={props.phoneNumber}
      />
    </ButtonGroupStyled>
  );
}

/**
 * Primary UI component for user interaction
 */
export const InviteContactsScreen = ({ ...props }) => {
  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [state, setState] = useState({
    phoneNumber: "",
    loading: false,
    disabled: true,
    toasterMessage: "",
    loading: true,
    authFlow: p.authFlow
  });
  const [toasterMessage, setToasterMessage] = useState();
  const navigate = useNavigate();
  const localP = useRef({ localLoading: false });

  const onChange = (event) => {
    setState({
      ...state,
      disabled: !/^\d{6,}$/.test(event.currentTarget.value),
      phoneNumber: event.currentTarget.value,
    });
  };

  const sendInvite = async () => {

    q.updateState(globalState);

    try {
      const res = await q.doQuery(
        "createInvite",
        "Phone number has been invited",
        "An error occured",
        {},
        p,
        `${globalState?.authFlow?.countryObj?.dial_code}${state.phoneNumber}`
      );

      if (res) {
        setState({
          ...state,
          phoneNumber: "",
          data: [
            ...state.data,
            { phoneNumber: res?.data?.createInvite?.phoneNumber },
          ],
        });
      } else {
        // setState({
        //   ...state,
        //   loading: false,
        // });
      }
    } catch (error) {
      console.log("error", error);
      // setState({
      //   ...state,
      //   loading: false,
      // });
    }
  };

  const onCancelInvitation = async (event) => {
    q.updateState(globalState);

    const phoneNumber = event.currentTarget.getAttribute("phoneNumber");
    const index = event.currentTarget.getAttribute("index");

    const deletePhoneNumberFunc = async (phoneNumber, index) => {
      localP.current.localLoading = true;

      const res = await q.doQuery(
        "deleteInvite",
        "Phone number has been uninvited",
        "An error occured",
        {},
        p,
        `${phoneNumber}`
      );

      const newArr = [...state.data];
      newArr.splice(parseInt(index), 1);

      setState({
        ...state,
        data: newArr,
      });

      localP.current.localLoading = false;
    };

    props.setAppDialog({
      ...props.appDialog,
      confirmText: "Cancel Invite",
      dialogChildren: (
        <Stack px={3}>
          <Typography variant="body1">
            Are you sure you want to cancel this invitation?
          </Typography>
        </Stack>
      ),
      open: true,
      onConfirm: async () => {
        deletePhoneNumberFunc(phoneNumber, index);
      },
    });
  };

  useEffect(() => {
    (async () => {
      const res = await client.graphql({
        query: listInvites(globalState?.userData?.userId),
      });

      setState({
        ...state,
        authFlow: p.authFlow,
        data: res.data?.listInvites?.items || [],
        loading: false,
      });
    })();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"}>
        <Box mb={4}>
          <ScreenNavHeader title="Invite" to="/contacts" />
        </Box>

        <Stack alignItems={"center"}>
          <Box
            display={"flex"}
            sx={{
              background: "#F1F3FC",
              borderRadius: "100%",
              p: 1,
              width: 48,
              height: 48,
            }}
          >
            <SvgIcon sx={{ width: 48, height: 48, color: "#2D9CDB" }}>
              <AddIcon />
            </SvgIcon>
          </Box>
        </Stack>

        <BasicButtonGroup
          phoneNumber={state.phoneNumber}
          authFlow={state?.authFlow}
          onChange={onChange}
        />

        <Box mb={6}>
          <Button disabled={globalState?.queryLoading || state.loading} onClick={sendInvite}>
            {globalState?.queryLoading && !localP.current.localLoading && (
              <CircularProgress
                size={18}
                sx={{
                  mr: 1,
                  ml: -0.5,
                  color: "rgba(0,0,0,0.25)",
                }}
              />
            )}
            Send
          </Button>
        </Box>

        <Box>
          <Stack mb={2} direction={"row"} alignItems={"center"} spacing={1}>
            <Typography fontWeight={700}>Invited Numbers</Typography>
            {!state.loading && (
              <Box
                sx={{
                  background: "#8EC8E9",
                  borderRadius: "12px",
                  width: "26px",
                  height: "16px",
                  padding: "8px",
                  alignItems: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body2" textAlign={"center"}>
                  {state.data?.length}/6
                </Typography>
              </Box>
            )}
          </Stack>
          {!state.loading && (
            <>
              {state.data?.length > 0 ? (
                state.data?.map((itemObj, i) => {
                  return (
                    <>
                      <Stack
                        direction="row"
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        spacing={1}
                        mb={2}
                      >
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={1}
                        >
                          <Avatar />
                          <Typography>{itemObj.phoneNumber}</Typography>
                        </Stack>
                        <IconButton
                          index={i}
                          phoneNumber={itemObj.phoneNumber}
                          onClick={onCancelInvitation}
                          disabled={state.loading || globalState?.queryLoading}
                        >
                          <CancelScheduleSendIcon />
                        </IconButton>
                      </Stack>
                    </>
                  );
                })
              ) : (
                <Typography variant="body1" fontStyle={"italic"}>
                  You have not invited any numbers.
                </Typography>
              )}
            </>
          )}
          {(state.loading ||
            (globalState?.queryLoading && localP.current.localLoading)) && (
            <CircularProgress sx={{ color: "#2D9CDB" }} size={32} />
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export const InviteContactsScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <InviteContactsScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

InviteContactsScreen.propTypes = {};

InviteContactsScreen.defaultProps = {
  color: "primary",
};
