const getChatChannelBySortedP2PUserIDS = (receiverID, senderID, userID=null, input=null) => `
query GetChatChannelBySortedP2PUserIDs {
  q1: getChatChannelBySortedP2PUserIDS(sortedP2PUserIDSPK: "${receiverID}#${senderID}") {
    createdAt
    updatedAt
    name
    id
    createdByID
    latestMessageAt
    chatChannelMessages(limit: 600) {
      nextToken
      items {
        content {
          data
          text
        }
        id
        type
        updatedAt
        createdAt
        createdByID
        channelID
        messageToken
        createdBy {
          email
          familyName
          givenName
          id
          phoneNumber
          images {
            items {
              id
              index
              url
            }
          }
        }
      }
    }
    chatChannelMembers {
      items {
        user {
          email
          id
          familyName
          givenName
          phoneNumber
          numberOfVotes
          sumOfVotes
          images {
            items {
              id
              url
              index
            }
          }
        }
        id
        userID
        channelID
        createdAt
        updatedAt
        status
        role
      }
    }
    latestMessage {
      content {
        data
        text
      }
      createdAt
      updatedAt
      type
      id
      createdByID
      channelID
      messageToken
      createdBy {
        email
        familyName
        givenName
        id
        images {
          items {
            id
            url
            index
          }
        }
      }
    }
  }
  q2: getChatChannelBySortedP2PUserIDS(sortedP2PUserIDSPK: "${senderID}#${receiverID}") {
    createdAt
    updatedAt
    name
    id
    createdByID
    latestMessageAt
    chatChannelMessages(limit: 600) {
      nextToken
      items {
        content {
          data
          text
        }
        id
        type
        updatedAt
        createdAt
        createdByID
        channelID
        messageToken
        createdBy {
          email
          familyName
          givenName
          id
          phoneNumber
          images {
            items {
              id
              index
              url
            }
          }
        }
      }
    }
    chatChannelMembers {
      items {
        user {
          email
          id
          familyName
          givenName
          phoneNumber
          numberOfVotes
          sumOfVotes
          images {
            items {
              id
              url
              index
            }
          }
        }
        id
        userID
        channelID
        createdAt
        updatedAt
        status
        role
      }
    }
    latestMessage {
      content {
        data
        text
      }
      createdAt
      updatedAt
      type
      id
      createdByID
      channelID
      messageToken
      createdBy {
        email
        familyName
        givenName
        id
        images {
          items {
            id
            url
            index
          }
        }
      }
    }
  }
}`;

export default getChatChannelBySortedP2PUserIDS;