import React, { useEffect, useState, useContext } from "react";
import AppContext from "../../AppContext";
import { Link as ReactLink, useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Container,
  Stack,
  InputBase,
  Skeleton,
  SvgIcon,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import { AvatarLink } from "../basic/AvatarLink";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { generateClient, post } from "aws-amplify/api";
import getCommentsByPost from "./queries/getCommentsByPost.js";
import createComment from "./queries/createComment.js";
import SpeakerNotesOffOutlinedIcon from "@mui/icons-material/SpeakerNotesOffOutlined";
import timestampdifference from "../utils/timestampdifference";
import { cleanText } from "../../ui-components/utils.js";
import SendIcon from "@mui/icons-material/Send";

const client = generateClient();

const theme = createTheme({
  palette: {},
  components: {
    // MuiInputBase multiline: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

/**
 * Primary UI component for user interaction
 */
export const CommentsScreen = ({ ...props }) => {
  const [state, setState] = useState({ data: [], nextToken: null });
  const [isLoading, setIsLoading] = useState(true);
  const [commentText, setCommentText] = useState("");
  const [globalState, setGlobalState] = useContext(AppContext);

  const params = useParams();

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const res = await client.graphql({
        query: getCommentsByPost(
          state.nextToken ? `"${state.nextToken}"` : null,
          params.id
        ),
      });

      setState({
        ...state,
        data: [...res.data.getCommentsByPost.items],
        nextToken: res.data.getCommentsByPost.nextToken,
        sendLoading: false,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const submitComment = async (postID, comment) => {
    const res = await client.graphql({
      query: createComment(
        `{categoryID: "${postID}", category: POST, comment: "${cleanText(
          comment
        )}"}`
      ),
    });
    // console.log("res", res);
  };

  const handleCommentSubmission = async (event) => {
    if (
      event.type === "click" ||
      (event.type === "keyup" && event.key === "Enter")
    ) {
      event.preventDefault();
      if (commentText.length >= 300 || commentText.trim() === "") return;
      setCommentText("");
      setState({
        ...state,
        sendLoading: true,
      });
      await submitComment(params.id, commentText);
      fetchData();
    } else {
      setCommentText(event.target.value);
    }
  };

  useEffect(() => {
    (async () => {
      fetchData();
    })();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"}>
        <Box>
          <ScreenNavHeader title="Comments" />

          <Box>
            <Stack py={4} direction={"row"} spacing={2}>
              <AvatarLink
                to={`/profile/${globalState?.userData?.dynamoDB?.id}`}
                src={globalState?.userData?.dynamoDB?.images?.items[0]?.url}
                sx={{ width: 48, height: 48 }}
              />
              <Stack direction={"column"} sx={{ flexGrow: 1 }}>
                <Stack direction="row" spacing={1} alignItems="flex-end">
                  <InputBase
                    onKeyUp={handleCommentSubmission}
                    onChange={(e) => setCommentText(e.target.value)}
                    multiline={true}
                    placeholder="Write a comment..."
                    value={commentText}
                    sx={{ flexGrow: 1 }}
                  />
                  <IconButton
                    sx={{
                      padding: 1,
                    }}
                    onClick={handleCommentSubmission}
                    disabled={
                      commentText.trim() === "" || commentText.length >= 300
                    }
                    size="small"
                  >
                    <SvgIcon>
                      <SendIcon />
                    </SvgIcon>
                  </IconButton>
                </Stack>
                {commentText?.length >= 250 && (
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{
                      color: commentText?.length >= 250 ? "red" : "inherit",
                    }}
                  >
                    Character count: {commentText?.length}/300
                  </Typography>
                )}
              </Stack>
            </Stack>

            {!isLoading && !state.sendLoading && state.data.length === 0 && (
              <>
                <Stack direction="column" alignItems={"center"}>
                  <SvgIcon sx={{ mb: 3 }}>
                    <SpeakerNotesOffOutlinedIcon />
                  </SvgIcon>
                  <Typography variant="h5" fontWeight={700} sx={{ mb: 2 }}>
                    No Comments
                  </Typography>
                  <Typography variant="h6">
                    Be the first to leave a comment.
                  </Typography>
                </Stack>
              </>
            )}

            {isLoading &&
              !state.sendLoading &&
              state.data.length === 0 &&
              [1].map((v, i) => (
                <Stack
                  key={`skeleton-activity-${i}`}
                  direction={"column"}
                  spacing={1}
                  mb={4}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={2}
                    sx={{ width: "100%" }}
                  >
                    <Skeleton
                      animation={"wave"}
                      variant="circular"
                      width={48}
                      height={48}
                    />
                    <Stack
                      direction={"row"}
                      spacing={1}
                      flexGrow={1}
                      justifyContent={"space-between"}
                    >
                      <Stack direction={"column"} spacing={1}>
                        <Skeleton variant="rounded" width={160} height={10} />
                        <Skeleton
                          variant="rounded"
                          width={100}
                          height={10}
                          sx={{ flexGrow: 1 }}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              ))}

            {state.sendLoading && (
              <Stack mt={0} mb={4} direction="row" spacing={2}>
                <Skeleton
                  variant="circular"
                  sx={{ width: 48, height: 48 }}
                ></Skeleton>
                <Stack direction={"column"} spacing={1}>
                  <Skeleton
                    animation={"wave"}
                    variant="rounded"
                    width={150}
                    height={12}
                  />
                  <Skeleton
                    animation={"wave"}
                    variant="rounded"
                    width={100}
                    height={10}
                  />
                </Stack>
              </Stack>
            )}

            {state.data.map((commentObj, i) => (
              <Stack
                key={`comment-${i}`}
                direction={"row"}
                spacing={2}
                alignItems={"flex-start"}
                mt={0}
                mb={4}
              >
                <AvatarLink
                  to={`/profile/${commentObj?.userObject?.id}`}
                  sx={{ width: 48, height: 48 }}
                  src={commentObj?.userObject?.images?.items?.[0]?.url}
                />
                <Stack direction={"column"} sx={{ flexGrow: 1 }}>
                  <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                    <ReactLink to={`/profile/${commentObj?.userObject?.id}`}>
                      <Typography variant="body1">
                        {commentObj?.userObject?.givenName}{" "}
                        {commentObj?.userObject?.familyName}
                      </Typography>
                    </ReactLink>
                    <Typography color="textSecondary" variant="caption">
                      · {timestampdifference(new Date(), commentObj?.createdAt)}
                    </Typography>
                  </Stack>
                  <Typography variant="body2">{commentObj?.comment}</Typography>
                </Stack>
              </Stack>
            ))}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export const CommentsScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <CommentsScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

CommentsScreen.propTypes = {};

CommentsScreen.defaultProps = {
  color: "primary",
};
