import React from "react";
import { IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { EmbeddedContent } from "../basic/EmbeddedContent.jsx";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

function ensureProtocolAndSubdomain(url) {
  // Check if URL has a protocol
  if (!/^https?:\/\//i.test(url)) {
      // If no protocol, add http:// as default
      url = 'http://' + url;
  }

  try {
      // Create a URL object from the input URL
      const parsedUrl = new URL(url);

      // Check if the protocol is either 'http:' or 'https:'
      const hasValidProtocol = parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:';

      // Split the hostname into parts
      const hostnameParts = parsedUrl.hostname.split('.');

      // Check if there are at least three parts (subdomain.domain.tld)
      const hasSubdomain = hostnameParts.length > 2;

      return {
          url: parsedUrl.href, // Return the URL with protocol if it was missing
          hasValidProtocol,
          hasSubdomain
      };
  } catch (e) {
      // URL constructor throws an error for invalid URLs
      return {
          url: null,
          hasValidProtocol: false,
          hasSubdomain: false
      };
  }
}

/**
 * Primary UI component for user interaction
 */
export const LinkAttachment = ({ ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <Stack
        direction={"column"}
        spacing={1}
        sx={{ maxWidth: { xs: 300, sm: 400 } }}
        mb={4}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          {props.title && <Typography fontWeight={700}>Link</Typography>}
          <IconButton onClick={props.onClearLink} size="small" variant="outlined">
            <SvgIcon sx={{ width: 16, height: 16 }}>
              <CloseIcon />
            </SvgIcon>
          </IconButton>
        </Stack>
        <EmbeddedContent type="link" url={props.link} linkTo={props.link} />
      </Stack>
    </ThemeProvider>
  );
};

LinkAttachment.propTypes = {};

LinkAttachment.defaultProps = {
  color: "primary",
};
