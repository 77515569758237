
const getCommentsByPost = (nextToken=null, postID=null) =>`
query MyQuer {
  getCommentsByPost(postID: "${postID}", sortDirection: DESC, limit: 10, nextToken: ${nextToken}) {
    items {
      id
      comment
      createdAt
      userObject {
        id
        givenName
        familyName
        phoneNumber
        numberOfVotes
        sumOfVotes
        images(limit: 1) {
          items {
            id
            index
            url
          }
        }
      }
    }
    nextToken
  }
}
`;

export default getCommentsByPost;