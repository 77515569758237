import React, { useContext, useEffect, useRef, useState } from "react";
import { Link as ReactLink } from "react-router-dom";
import AppContext from "../../AppContext";
import {
  Avatar,
  Box,
  Button as MuiButton,
  Container,
  IconButton,
  InputAdornment,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import Search from "@mui/icons-material/Search";
import Fuse from "fuse.js";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { AvatarLink } from "../basic/AvatarLink.jsx";
import { Button } from "../basic/Button";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { SearchTextField } from "../basic/SearchTextField";
import AddIcon from "@mui/icons-material/Add";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import contactsByUserID from "./queries/contactsByUserID.js";
import { generateClient } from "aws-amplify/api";
const client = generateClient();

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

const contactStatusMap = {
  REQUESTED: "Invitation sent",
  ACCEPTED: "Contact",
};
/**
 * Primary UI component for user interaction
 */
export const ContactsScreen = ({ ...props }) => {
  const [globalState, setGlobalState] = useContext(AppContext);
  const [state, setState] = useState({
    data: [],
    nextToken: null,
    loading: true,
  });
  const timeoutId = useRef(null);
  const fuse = useRef();
  const originalData = useRef();

  const fetchData = async () => {
    try {
      const res = await client.graphql({
        // query: contactsByUserID("9d1d5590-7b65-4e09-8bce-64035ecfbed5")
        query: contactsByUserID(globalState?.userData?.userId),
      });

      originalData.current = [...res.data.contactsByUserID.items];

      setState({
        ...state,
        data: [...res.data.contactsByUserID.items],
        nextToken: res.data.contactsByUserID.nextToken,
        loading: false,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    (async () => {
      if (!globalState?.userData?.userId) return;
      fetchData();
    })();
  }, [globalState?.userData]);

  const searchContacts = async (event) => {
    const keyword = event.currentTarget.value;

    // Clear previous search invocation if called prior to timeout
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    // Set new timeout id
    const newTimeoutId = setTimeout(async () => {
      if (!keyword) {
        setState({ ...state, data: originalData.current });
        return;
      } else {
        setState({
          ...state,
          data: new Fuse(originalData.current, {
            threshold: 0.7,
            keys: ["contact.givenName", "contact.familyName"],
          })
            .search(keyword)
            .map((obj) => {
              return { ...obj.item };
            }),
        });
      }
    }, [500]);

    // Set the timeout id in state
    timeoutId.current = newTimeoutId;
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"}>
        <Box>
          <ScreenNavHeader title="Contacts">
            <Button component={ReactLink} to="/contacts/add">
              Invite
            </Button>
          </ScreenNavHeader>
        </Box>

        <Box mb={2.5}>
          <SearchTextField
            fullWidth={true}
            size="medium"
            placeholder="Search"
            sx={{ borderRadius: "100%" }}
            onChange={searchContacts}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="Search" edge="end">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box>
          {!state.loading &&
            state.data.map((userObject, i) => (
              <Stack
                key={`comment-${i}`}
                direction={"row"}
                spacing={2}
                alignItems={"flex-start"}
                mb={4}
              >
                <AvatarLink
                  to={`/profile/${userObject?.contact?.id}`}
                  sx={{ width: 48, height: 48 }}
                  src={userObject?.contact?.images?.items?.[0]?.url}
                />
                <Stack direction={"column"} sx={{ flexGrow: 1 }}>
                  <ReactLink to={`/profile/${userObject?.contact?.id}`}>
                    <Typography variant="body1">
                      {userObject?.contact?.givenName}{" "}
                      {userObject?.contact?.familyName}
                    </Typography>
                  </ReactLink>
                  <Typography variant="body2">
                    {contactStatusMap[userObject?.status]}
                  </Typography>
                </Stack>
                <IconButton
                  component={ReactLink}
                  to={`/chat/${userObject?.sortedUserIDSPK.replace("#", ",")}`}
                >
                  <SvgIcon>
                    <ChatBubbleIcon />
                  </SvgIcon>
                </IconButton>
              </Stack>
            ))}

          {state.loading &&
            state.data.length === 0 &&
            [1].map((v, i) => (
              <Stack
                key={`skeleton-activity-${i}`}
                direction={"column"}
                spacing={1}
                mb={4}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={2}
                  sx={{ width: "100%" }}
                >
                  <Skeleton
                    animation={"wave"}
                    variant="circular"
                    width={48}
                    height={48}
                  />
                  <Stack
                    direction={"row"}
                    spacing={1}
                    flexGrow={1}
                    justifyContent={"space-between"}
                  >
                    <Stack direction={"column"} spacing={1}>
                      <Skeleton variant="rounded" width={160} height={10} />
                      <Skeleton
                        variant="rounded"
                        width={100}
                        height={10}
                        sx={{ flexGrow: 1 }}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            ))}
        </Box>

        {/* <AppDialog open={true}>
          <Avatar>S</Avatar>
          <Stack>
            <Button>Send a Message</Button>
          </Stack>
        </AppDialog> */}
      </Container>
    </ThemeProvider>
  );
};

export const ContactsScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <ContactsScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

ContactsScreen.propTypes = {};

ContactsScreen.defaultProps = {
  color: "primary",
};
