import React, { useContext, useEffect } from "react";
import AppContext from "../../AppContext";
import { Box, Container, Stack, Typography } from "@mui/material";
import { OnboardingCarousel } from "../complex/OnboardingCarousel";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { AWSAuthenticator } from "../complex/AWSAuthenticator";
import "./SignIn.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import logoShape from "../assets/logo-shape.svg";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#fff",
      dark: "#eee",
    },
  },
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

const LayoutBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    // flexDirection: 'row',
    alignItems: "center",
  },
  [theme.breakpoints.down("sm")]: {
    maxWidth: 300,
  },
}));

const SignInContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    borderRadius: "20px",
  },
}));

const CarouselContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    marginTop: 0,
  },
}));

const LogoBox = styled(Box)(({ theme }) => ({
  width: 64,
  [theme.breakpoints.down("sm")]: {
    width: 32,
  },
}));

const Logo = (props) => {
  return (
    <Stack
      flexDirection={"row"}
      alignItems={"center"}
      spacing={2}
      sx={{ width: props.width }}
    >
      <LogoBox pl={2}>
        <img width="100%" src={logoShape} />
      </LogoBox>
      <Box
        sx={{
          marginTop: "-4px !important",
          marginLeft: "14px !important",
        }}
      >
        <Typography
          sx={{ marginTop: 0 }}
          component="p"
          variant="h6"
          color="white"
          fontWeight={700}
        >
          Sociogram
        </Typography>
      </Box>
    </Stack>
  );
};

/**
 * Primary UI component for user interaction
 */
export const SignIn = ({ ...props }) => {
  /*
   * Callback for when steps change in OnboardingCarousel
   */
  const [bgIndex, setBgIndex] = React.useState(0);
  const [globalState] = useContext(AppContext);

  const bgColors = [
    "rgba(45, 156, 219, 0.25)",
    "rgba(252, 186, 4, 0.5)",
    "rgba(185, 222, 45, 0.5)",
  ];

  return (
    <Box position={"relative"}>
      <Stack
        sx={{
          position: "absolute",
          zIndex: 10,
          width: "calc(100% - 32px)",
          left: 0,
        }}
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
        py={3}
        px={2}
      >
        <Logo width={170} />

        {useMediaQuery(theme.breakpoints.up("md")) && (
          <Stack
            sx={{ width: 400 }}
            direction="row"
            justifyContent={"space-between"}
          >
            <Typography variant="h6" color="white">
              FAQs
            </Typography>
            <Typography variant="h6" color="white">
              Community Guidelines
            </Typography>
            <Typography variant="h6" color="white">
              Contact
            </Typography>
          </Stack>
        )}
        <Box sx={{ width: 170 }} />
      </Stack>

      <Box
        sx={{
          boxSizing: "border-box",
          width: "100%",
          // background: "url(./bg-2.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          minHeight: "100vh",
          height: "100%",
        }}
      >
        {/* <div style={{"padding":"75% 0 0 0", "position": "relative;"}}><iframe src="https://player.vimeo.com/video/911804083?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style={{"position":"absolute",top:"0",left:0, width:"100%",height:"100%"}} title="pexels_videos_2022396 (1080p)"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script> */}

        {/* <video 
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
        autoplay muted loop>
          <source src="./pexels_videos_2022396 (1080p).mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}

        <Box sx={{
          width: "100vw",
          height: "100vh",
          position: "absolute",
          background: 'black',
          zIndex: 1
        }} />
        <Box
          component="img"
          src="./pexels_videos_2022396 (1080p).gif"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100vw",
            height: "100vh",
            objectFit: "cover",
            zIndex: 2,
          }}
        ></Box>

        {/** blue opacity */}
        <Box
          sx={{
            width: "100%",
            minHeight: "100vh",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            background: bgColors[bgIndex],
            transition: "background 10s",
            zIndex: 3
          }}
        />

        <LayoutBox
          display={"flex"}
          flexDirection={"column"}
          disableGutters
          sx={{
            // minHeight: '100vh',
            // height: '100%',
            // margin: '0 auto',
            marginTop: 4,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 4
          }}
        >
          {/** carousel */}
          <CarouselContainer
            disableGutters
            maxWidth="xs"
            sx={{
              position: "relative",
              margin: "0px auto",
            }}
          >
            <OnboardingCarousel callback={setBgIndex} />
          </CarouselContainer>

          {/** sign up */}
          <SignInContainer
            disableGutters
            maxWidth="xs"
            sx={{
              bottom: "0px",
              width: "calc(100% - 0px)",
              padding: "20px 20px 40px 20px",
              borderRadius: "20px 20px 0 0",
              position: "relative",
              zIndex: 10,
            }}
          >
            <div className="aws-authenticator">
              <AWSAuthenticator />
            </div>
            <Typography align="center" color="white" variant="body2">
              By signing in, I agree to the <br />
              <u>Privacy Policy</u> and <u>Terms & Conditions</u>
            </Typography>
          </SignInContainer>
        </LayoutBox>
      </Box>
    </Box>
  );
};

SignIn.propTypes = {};

SignIn.defaultProps = {
  color: "primary",
};
