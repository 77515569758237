import uploadImages from "./sharedFunctions/uploadImages";
import createActivity from "./queries/createActivity.js";
import deleteActivity from "./queries/deleteActivity.js";
import createConnection from "./queries/createConnection";
import deleteConnection from "./queries/deleteConnection";
import updateUser from "./queries/updateUser.js";
import createActivityRequest from "./queries/createActivityRequest.js";
import updateConnectionStatus from "./queries/updateConnectionStatus.js";
import updateActivityAttendanceStatus from "./queries/updateActivityAttendanceStatus.js";
import createInvite from "./queries/createInvite.js";
import deleteInvite from "./queries/deleteInvite.js";
import listInvites from "./queries/listInvites.js";
import createPost from "./queries/createPost.js";
import deletePost from "./queries/deletePost.js";
import inviteToActivity from "./queries/inviteToActivity.js";
import { generateClient } from "aws-amplify/api";
import { cleanText } from "../../ui-components/utils.js";

import {
  fetchAuthSession,
  getCurrentUser,
  fetchUserAttributes,
} from "aws-amplify/auth";
import getUser from "./queries/getUser.js";
import getUser_paired from "./queries/getUser-paired.js";
import updateUserDevice from "./queries/updateUserDevice.js";

const client = generateClient();

export const createNewActivity = async (
  state,
  setState,
  globalState,
  setGlobalState,
  navigate
) => {
  setState({
    ...state,
    loading: true,
  });

  if (globalState.submission?.name) {
    try {
      const linkObj = globalState.submission?.link?.url
        ? `link: {
          url: "${globalState.submission?.link?.url}",
          info: "${globalState.submission?.link?.info}"
        },`
        : ``;

      // 0. Create query string
      const queryString =
        `input: {
          description: "${globalState.submission?.description}",
          name: "${globalState.submission?.name}",
          status: ${globalState.submission?.status},
          privacy: ${globalState.submission?.privacy.toUpperCase()},
          locationType: ${globalState.submission?.locationType},
          countryKey: "${globalState.submission?.countryKey}",
          city: "${globalState.submission?.city}",
          activityEnd: "${globalState.submission?.activityEnd}", 
          activityStart: "${globalState.submission?.activityStart}",
          numberOfParticipants: ${
            globalState.submission?.numberOfParticipants
          },` +
        `conditions: ${globalState.submission?.conditions},
          interestID: "${globalState.submission?.interestID}",
          languageIDS: ${
            globalState.submission?.languageIDS?.length > 0
              ? JSON.stringify(globalState.submission?.languageIDS)
              : JSON.stringify(["en"])
          } ,
          fromAge: ${globalState.submission?.fromAge},
          toAge: ${globalState.submission?.toAge},
          currency: "${globalState.submission?.currency}",
          price: ${globalState.submission?.price || 0},` +
        // Include payment methods (if any)
        (globalState.submission?.paymentMethod
          ? `paymentMethod: ${globalState.submission?.paymentMethod},`
          : "") +
        // Include external links (if any)
        `${linkObj}` +
        // Include address (if included)
        (globalState.submission?.location
          ? `latitude: ${globalState.submission?.location?.latitude},
          longitude: ${globalState.submission?.location?.longitude},
          locationDescription: "${globalState.submission?.location?.locationDescription}",
          address: "${globalState.submission?.location?.q}"`
          : "") +
        // Close bracket
        `}`;

      // 1. Create the activity
      const result = await client.graphql({
        query: createActivity(queryString),
      });

      // 2. Get the activity id
      const activityID = result.data?.createActivity?.id;

      // 3. Upload attached images (if any)
      if (globalState.submission?.files) {
        await uploadImages(
          "ACTIVITY",
          globalState.submission?.files,
          activityID,
          //Callback for when images are done uploading
          () => {
            alert("Activity created succesfully!");
            setState({
              ...state,
              loading: false,
            });
          }
        );
      }

      navigate(`/create-activity/share/${activityID}`);
    } catch (err) {
      console.log(err);
      setState({
        ...state,
        loading: false,
      });
    }
  }
  setState({
    ...state,
    loading: false,
  });
};

function Query() {
  this.init = function (globalState, setGlobalState, p) {
    this.globalState = globalState;
    this.setGlobalState = setGlobalState;
    // this.p = p;
    this.doQuery = this.doQuery.bind(this);
    this.updateState = this.updateState.bind(this);

    this.createNewProfile = this.createNewProfile.bind(this);
    this.createNewActivity = this.createNewActivity.bind(this);
    this.createNewPost = this.createNewPost.bind(this);
    this.deletePost = this.deletePost.bind(this);
    this.deleteConnection = this.deleteConnection.bind(this);

    this.updateActivity = this.updateActivity.bind(this);
    this.deleteActivity = this.deleteActivity.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.updateImageOfUser = this.updateImageOfUser.bind(this);
    this.createActivityRequest = this.createActivityRequest.bind(this);
    this.updateActivityAttendanceStatus =
      this.updateActivityAttendanceStatus.bind(this);
    this.updateConnectionStatus = this.updateConnectionStatus.bind(this);
    this.leaveActivity = this.leaveActivity.bind(this);
    this.rejoinActivity = this.rejoinActivity.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.createInvite = this.createInvite.bind(this);
    this.deleteInvite = this.deleteInvite.bind(this);
    this.listInvites = this.listInvites.bind(this);
    this.inviteToActivity = this.inviteToActivity.bind(this);
    this.getUser = this.getUser.bind(this);
    this.getUserData = this.getUserData.bind(this);
    this.getUserData_paired = this.getUserData_paired.bind(this);
    this.getUserCognitoData = this.getUserCognitoData.bind(this);
  };

  this.updateState = function (globalState) {
    this.globalState = globalState;
  };
  // Method for launching toasters and updating loading
  // status.
  this.doQuery = async function (
    callback,
    successMessage,
    errorMessage,
    newGlobalState = {},
    p,
    ...args
  ) {
    this.setGlobalState({
      ...this.globalState,
      queryLoading: true,
      toaster: {
        open: false,
        message: "",
        type: "info",
      },
    });
    try {
      const res = await this[callback](...args);
      this.setGlobalState({
        ...this.globalState,
        toaster: {
          ...this.globalState?.toaster,
          open: true,
          type: "success",
          message: successMessage,
        },
        queryLoading: false,
        ...newGlobalState,
      });
      p.submission = {
        ...newGlobalState.submission,
      };
      return res;
    } catch (error) {
      console.log("Something went wrong", error);
      this.setGlobalState({
        ...this.globalState,
        toaster: {
          ...this.globalState?.toaster,
          open: true,
          type: "error",
          message: `${errorMessage}: ${
            error.message || error?.errors?.[0]?.message
          }`,
        },
        queryLoading: false,
      });
      return false;
    }
  };

  this.createNewProfile = async (
    userId,
    givenName,
    familyName,
    bio,
    email,
    otherInput = "",
    callback
  ) => {
    var apiKey = "da2-22ztnhuamje4jptwonwzfafdi4"; // Replace 'xxx' with your actual API key
    const url =
      "https://3arkzxpkarhoflbprgz4tpruai.appsync-api.us-east-1.amazonaws.com/graphql";
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": apiKey,
    };

    const queryString = updateUser(`
    input: {
      id: "${userId}",
      givenName: "${cleanText(givenName)}", 
      familyName: "${cleanText(familyName)}",
      bio: "${cleanText(bio)}",
      email: "${cleanText(email)}",
      ${otherInput}
    }
  `);

    console.log("queryString", queryString);
    const body = JSON.stringify({
      query: queryString,
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: body,
      });

      const result = await response.json();
      console.log("updateUser result", result);
      callback();
      return result;
    } catch (error) {
      console.error("Error:", error);
      return error;
    }
  };

  this.createNewActivity = async (queryString) => {
    const res = await client.graphql({
      query: queryString,
    });

    return res;
  };

  this.createNewPost = async (
    status = null,
    goingToTag = null,
    text = null,
    linkInput = null,
    parentID = null,
    activity = null
  ) => {
    var queryString = createPost(
      status,
      goingToTag,
      text,
      linkInput,
      parentID,
      activity
    );
    console.log(queryString);
    const res = await client.graphql({
      query: queryString,
    });
    return res;
  };

  this.deletePost = async (id) => {
    const res = await client.graphql({
      query: deletePost(id),
    });
    return res;
  };

  this.createConnection = async (phoneNumber) => {
    const res = await client.graphql({
      query: createConnection(phoneNumber),
    });
    return res;
  };

  this.deleteConnection = async (id1, id2) => {
    const res1 = await client.graphql({
      query: deleteConnection(id1),
    });

    const res2 = await client.graphql({
      query: deleteConnection(id2),
    });

    return [res1, res2];
  };

  this.updateActivity = async (queryString) => {
    const res = await client.graphql({
      query: queryString,
    });

    return res;
  };


  this.deleteActivity = async (id) => {
    // const res = await client.graphql({
    //   query: deleteActivity(id),
    // });
    // return res;

    var apiKey = "da2-22ztnhuamje4jptwonwzfafdi4"; // Replace 'xxx' with your actual API key

    return await fetch(
      "https://3arkzxpkarhoflbprgz4tpruai.appsync-api.us-east-1.amazonaws.com/graphql",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify({
          query: deleteActivity(id),
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
        return error;
      });
  };  

  this.deleteImage = async (id) => {
    const res = await client.graphql({
      query: `mutation MyMutation {
          deleteImage(input: {id: "${id}"}) {
            id
          }
        }`,
    });

    return res;
  };

  this.updateImageOfUser = async (queryString) => {
    const res = await client.graphql({
      query: queryString,
    });

    return res;
  };

  // For joining activities
  this.createActivityRequest = async (activityID) => {
    const res = await client.graphql({
      query: createActivityRequest(activityID),
    });

    return res;

    // var apiKey = "da2-22ztnhuamje4jptwonwzfafdi4"; // Replace 'xxx' with your actual API key

    // await fetch(
    //   "https://3arkzxpkarhoflbprgz4tpruai.appsync-api.us-east-1.amazonaws.com/graphql",
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       "x-api-key": apiKey,
    //     },
    //     body: JSON.stringify({
    //       query: createActivityRequest(activityID),
    //     }),
    //   }
    // )
    //   .then((response) => response.json())
    //   .then((res) => {
    //     return res;
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //   });
  };

  this.updateActivityAttendanceStatus = async (attendanceID, status) => {
    const res = await client.graphql({
      query: updateActivityAttendanceStatus(attendanceID, status),
    });

    return res;

    // var apiKey = "da2-22ztnhuamje4jptwonwzfafdi4"; // Replace 'xxx' with your actual API key

    // await fetch(
    //   "https://3arkzxpkarhoflbprgz4tpruai.appsync-api.us-east-1.amazonaws.com/graphql",
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       "x-api-key": apiKey,
    //     },
    //     body: JSON.stringify({
    //       query: updateActivityAttendanceStatus(attendanceID, status),
    //     }),
    //   }
    // )
    //   .then((response) => response.json())
    //   .then((res) => {
    //     return res;
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //     return error;
    //   });
  };

  this.leaveActivity = async (attendanceID, status) => {
    var apiKey = "da2-22ztnhuamje4jptwonwzfafdi4"; // Replace 'xxx' with your actual API key

    return await fetch(
      "https://3arkzxpkarhoflbprgz4tpruai.appsync-api.us-east-1.amazonaws.com/graphql",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify({
          query: updateActivityAttendanceStatus(attendanceID, status),
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
        return error;
      });
  };

  this.rejoinActivity = async (attendanceID, status) => {
    var apiKey = "da2-22ztnhuamje4jptwonwzfafdi4"; // Replace 'xxx' with your actual API key

    return await fetch(
      "https://3arkzxpkarhoflbprgz4tpruai.appsync-api.us-east-1.amazonaws.com/graphql",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify({
          query: updateActivityAttendanceStatus(attendanceID, status),
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
        return error;
      });
  };

  this.updateConnectionStatus = async (sortedUserIDSPK, status) => {
    const res = await client.graphql({
      query: updateConnectionStatus(sortedUserIDSPK, status),
    });
    return res;
  };

  this.updateProfile = async (userId, givenName, familyName, bio, email) => {
    var apiKey = "da2-22ztnhuamje4jptwonwzfafdi4"; // Replace 'xxx' with your actual API key

    return await fetch(
      "https://3arkzxpkarhoflbprgz4tpruai.appsync-api.us-east-1.amazonaws.com/graphql",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify({
          query: updateUser(
            `input: {
                id: "${userId}"
                givenName: "${cleanText(givenName)}", 
                familyName: "${cleanText(familyName)}",
                bio: "${cleanText(bio)}",
                email: "${email}"
            }`
          ),
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  this.createInvite = async (phoneNumber) => {
    const res = await client.graphql({
      query: createInvite(phoneNumber),
    });

    return res;
  };

  this.deleteInvite = async (phoneNumber) => {
    const res = await client.graphql({
      query: deleteInvite(phoneNumber),
    });

    return res;
  };

  this.listInvites = async (userID) => {
    const res = await client.graphql({
      query: listInvites(userID),
    });

    return res;
  };

  this.inviteToActivity = async (activityID, userIDS) => {
    const res = await client.graphql({
      query: inviteToActivity(activityID, userIDS),
    });

    return res;
  };
  
  this.getUser = async (p) => {
    if (p.userData?.userId) {
      try {
        const userDataDynamoDB = await client.graphql({
          query: getUser(p?.userData.userId),
        });

        p.userData["dynamoDB"] = { ...userDataDynamoDB?.data?.getUser };
        console.log("userData", p.userData);
      } catch (error) {
        console.log("/getUser", error);
      }

      p.checkingUserData = true;

      // If user has email in Cognito or DynamDB, log them in
      const loggedIn = p.userData?.dynamoDB || p.userData?.email ? true : false;

      p = {
        ...p,
        interestLocationType: "PHYSICAL",
        selectedLanguages: ["en"],
        selectedLanguagesNamed: ["English"],
        notificationsCount: -1,
        initialized: true,
        authFlow: {
          countryObj: {
            name: "United Arab Emirates",
            code: "ae",
            dial_code: "+971",
          },
        },
        userData: p.userData,
        authenticatedChecked: true,
        loggedIn,
        submission: {
          status: "PUBLISHED",
          interestLocationType:
            p?.submission?.interestLocationType || "PHYSICAL",
          countryKey: "",
          city: "Dubai",
          // activityStart: new Date(Date.now()).toISOString(),
          // activityEnd: new Date(Date.now()).toISOString(),
        },
      };

      return p;
    }
  };

  this.getUserData = async (p) => {
    // if(p.loggingIn !== true) return;

    const userData = {
      ...(await getCurrentUser()),
      ...(await fetchUserAttributes()),
      ...fetchAuthSession(),
    };

    console.log("userData", userData);
    if (userData.userId) {
      // Update device id for notifications
      // Note: don't await this
      const res_userDeviceID = client.graphql({
        query: updateUserDevice(userData.userId),
      });

      try {
        const userDataDynamoDB = await client.graphql({
          query: getUser(userData.userId),
        });

        userData["dynamoDB"] = { ...userDataDynamoDB?.data?.getUser };
        console.log("userData", userData);
      } catch (error) {
        console.log("/getUser", error);
      }

      p.checkingUserData = true;

      // If user has email in Cognito or DynamDB, log them in
      const loggedIn = userData?.dynamoDB || userData?.email ? true : false;

      p = {
        ...p,
        interestLocationType: "PHYSICAL",
        selectedLanguages: ["en"],
        selectedLanguagesNamed: ["English"],
        notificationsCount: -1,
        initialized: true,
        authFlow: {
          countryObj: {
            name: "United Arab Emirates",
            code: "ae",
            dial_code: "+971",
          },
        },
        userData,
        authenticatedChecked: true,
        loggedIn,
        submission: {
          status: "PUBLISHED",
          interestLocationType:
            p?.submission?.interestLocationType || "PHYSICAL",
          countryKey: "",
          city: "Dubai",
          // activityStart: new Date(Date.now()).toISOString(),
          // activityEnd: new Date(Date.now()).toISOString(),
        },
      };

      return p;
    }
  };

  this.getUserData_paired = async (userId, userContactId) => {
    const userData_paired = {};
    try {
      const userDataDynamoDB = await client.graphql({
        query: getUser_paired(userId, userContactId),
      });

      userData_paired["dynamoDB"] = { ...userDataDynamoDB?.data?.getUser };
      console.log("userData_paired", userData_paired);
    } catch (error) {
      console.log("/getUser", error);
    }

    return userData_paired;
  };

  this.getUserCognitoData = async (p) => {
    const userData = {
      ...(await getCurrentUser()),
      ...(await fetchUserAttributes()),
      ...fetchAuthSession(),
    };

    if (userData.userId) {
      p = {
        ...p,
        authFlow: {
          countryObj: {
            name: "United Arab Emirates",
            code: "ae",
            dial_code: "+971",
          },
        },
        userData,
      };

      return p;
    }
  };
}

export default Query;
