import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Link as ReactLink,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import {
  Box,
  Button as MuiButton,
  CircularProgress,
  Container,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import AppContext from "../../AppContext";
import { Button } from "../basic/Button";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { TextField } from "../basic/TextField";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import { OnboardingScreenResponsiveLayout } from "../complex/OnboardingScreenResponsiveLayout";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { UploaderButton } from "../complex/UploaderButton";
import uploadImages from "./sharedFunctions/uploadImages";
import validateEmail from "../utils/validateEmail";
import getUser from "./queries/getUser";
import { generateClient } from "aws-amplify/api";
const client = generateClient();

const acceptableFiles = ["jpeg", "jpg", "png"];
const capitalizeWords = (string) => {
  const words = string.split(" ");
  const newString = words
    .map((word) => {
      if (word.length > 0) {
        return word[0]?.toUpperCase() + word?.slice(1);
      }
      {
        return word;
      }
    })
    .join(" ");
  return newString;
};

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const EditProfileScreen = ({ ...props }) => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [state, setState] = useState({
    // userData: p.userData,
    erros: p.errors,
    validated: p.validated || p.journey !== "onboarding" || true,
    loading: p.journey === "onboarding" ? false : true,
  });

  const mutex = useRef(p.mutex || 0);

  const reloadUserData = async () => {
    const res = await client.graphql({
      query: getUser(p.userData?.dynamoDB?.id),
    });

    p.submission = {
      givenName: res?.data?.getUser?.givenName || "",
      familyName: res?.data?.getUser?.familyName || "",
      bio: res?.data?.getUser?.bio || "",
      email: res?.data?.getUser?.email || "",
      imagesData: res?.data?.getUser?.images?.items,
      // ...p.submission,
      // ...res.data.getUser,
    };

    setState({
      ...state,
      errors: p.errors,
      validated: p.validated,
      submission: p.submission,
      loading: false,
      userData: {
        ...p.userData,
        ...globalState?.userData,
        dynamoDB: res.data.getUser,
      },
      // localChanges: p.localChanges
    });

    // setGlobalState({
    //   ...globalState,
    //   userData: {
    //     ...globalState?.userData,
    //     dynamoDB: res.data.getUser,
    //   },
    // });
  };

  useEffect(() => {
    (async () => {

      console.log("location", location)
      p.prevPage = p.currentPage || "edit-profile";
      p.currentPage = "edit-profile";
      p.notificationsFetchLock = 1;
      mutex.current = p.mutex || 1;

      if (p.journey === "onboarding") {
        console.log("--p", p);
        setState({
          ...state,
          errors: p.errors,
          validated: p.validated,
          submission: p.submission,
          loading: false,
          userData: {
            a: 1,
            ...p.userData,
          },
          b: 1,
        });
      } else {
        if (!p.journey && location.pathname !== "/edit-profile") {
          navigate("/")
        }
        reloadUserData();
      }
    })();
  }, []);

  const timeoutId = useRef(null);

  useEffect(() => {
    // Validate fields
    // ---------------------------------------------------------------

    // if (mutex.current === 0) {
      mutex.current = 1;
      p.mutex = mutex.current;
      const bioCondition =
        (state.submission?.bio?.length > 0 &&
          state.submission?.bio?.length <= 900) ||
        state.submission?.bio?.length === 0 ||
        !state.submission?.bio;

      if (
        state.submission?.givenName?.length <= 20 &&
        state.submission?.familyName?.length <= 20 &&
        state.submission?.givenName?.length >= 2 &&
        state.submission?.familyName?.length >= 2 &&
        bioCondition &&
        validateEmail(state.submission?.email)
      ) {
        p.validated = true;
      } else {
        p.validated = false;
      }
      mutex.current = 0;
      setState({
        ...state,
        submission: p.submission,
        errors: p.errors,
        validated: p.validated,
      });
    // }
  }, [
    state.submission?.givenName,
    state.submission?.familyName,
    state.submission?.bio,
    state.submission?.email,
  ]);

  const onChangeGivenName = (event) => {
    mutex.current = 1;
    p.mutex = mutex.current;
    const value = capitalizeWords(event.currentTarget.value);
    p.submission = {
      ...p.submission,
      givenName: value,
    };
    if (value.length >= 20) {
      p.errors = {
        ...p.errors,
        givenName: "Enter a valid first name.",
      };
    } else {
      p.errors = {
        ...p.errors,
        givenName: false,
      };
    }
    setState({
      ...state,
      submission: p.submission,
      errors: p.errors,
    });
  };

  const onChangeFamilyName = (event) => {
    mutex.current = 1;
    p.mutex = mutex.current;
    const value = capitalizeWords(event.currentTarget.value);
    p.submission = {
      ...p.submission,
      familyName: value,
    };

    if (value.length >= 20) {
      p.errors = {
        ...p.errors,
        familyName: "Enter a valid family name.",
      };
    } else {
      p.errors = {
        ...p.errors,
        familyName: false,
      };
    }

    setState({
      ...state,
      submission: p.submission,
      errors: p.errors,
    });
  };

  const onChangeBio = (event) => {
    mutex.current = 1;
    p.mutex = mutex.current;
    const value = event.currentTarget.value;
    p.submission = {
      ...p.submission,
      bio: value,
    };
    if (value.length > 900) {
      p.errors = {
        ...p.errors,
        bio: "You have exceeded the limit of 900 characters",
      };
    } else {
      p.errors = {
        ...p.errors,
        bio: false,
      };
    }
    setState({
      ...state,
      submission: p.submission,
      errors: p.errors,
    });
  };

  const onChangeEmail = (event) => {
    mutex.current = 1;
    p.mutex = mutex.current;
    const value = event.currentTarget.value;
    p.submission = {
      ...p.submission,
      email: value,
    };

    if (validateEmail(value)) {
      p.errors = {
        ...p.errors,
        email: false,
      };
    } else {
      p.errors = {
        ...p.errors,
        email: "Enter a valid first email address.",
      };
    }
    setState({
      ...state,
      submission: p.submission,
      errors: p.errors,
    });
  };

  const attachPhotoHandler = (event) => {
    const attachedFiles = event.target?.files
      ? Array.from(event.target?.files)
      : [];
    var cancelFlag = 0;

    if (attachedFiles.length > 11) {
      alert("Too many attachments. Max attachments is 1.");
      return;
    }

    if (attachedFiles) {
      // 1. Check file extensions
      attachedFiles.forEach((v, i) => {
        const file = v;
        const slashIndex = file.type.lastIndexOf("/");
        const fileExtension = file.type.substring(slashIndex + 1).toLowerCase();

        if (!acceptableFiles.includes(fileExtension)) {
          cancelFlag = 1;
          alert("This file format is not allowed.");
          return;
        }
      });
    }

    if (event?.target?.files) {
      const imagesData = [];

      Array.from(event.target.files).forEach((file, i) => {
        // const file = event.target.files[0];
        const previewURL = URL.createObjectURL(file);
        imagesData.push({
          label: file.name,
          url: previewURL,
        });
      });

      p.submission.imagesData = imagesData;
      p.submission.files = event.target.files;

      setState({
        ...state,
        submission: p.submission,
      });
    }
  };

  const onConfirmHandler = async (event) => {
    if (p.journey === "onboarding") {
      navigate("/profile-information/select-interests");
    } else {
      try {
        q.updateState(globalState);
        const res = await q.doQuery(
          "updateProfile",
          "Profile updated",
          "An error occured",
          {
            submission: p.submission,
          },
          p,
          p?.userData?.userId,
          state.submission?.givenName,
          state.submission?.familyName,
          state.submission?.bio,
          state.submission?.email
        );

        console.log("res", res)

        if (p.submission?.files) {
          await uploadImages(
            "PROFILE",
            p.submission?.files,
            p?.userData?.userId,
            "user/profile",
            //Callback for when images are done uploading
            () => {
              // alert("Activity created succesfully!");
              // setState({
              //   ...state,
              //   loading: false,
              // });
              // setGlobalState({
              //   ...globalState,
              //   currentUserData: {
              //     ...p.submission,
              //   },
              // });
              // navigate(-1);
              if(p.journey === "onboarding") {
                navigate("/profile-information/select-interests")
              } else {
                navigate(-1);
              }
            },
            (uploadProgress) => {
              console.log(uploadProgress);
            }
          );
        } else {
          if(p.journey === "onboarding") {
            navigate("/profile-information/select-interests")
          } else {
            navigate(-1);
          }
        }

        setGlobalState({
          ...globalState,
          userData: {
            ...globalState?.userData,
            dynamoDB: res.data.updateUser,
          },
        });
      } catch (error) {
        console.log("error", error);
      }
      // navigate(-1);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"}>
        <Box>
          <ScreenNavHeader
            backButtonDisabled={globalState?.queryLoading}
            title={`Edit Profile`}
            to={`/profile/${p.userData?.userId}`}
          >
            <Button
              onClick={onConfirmHandler}
              disabled={!state.validated || globalState?.queryLoading}
              size={"large"}
            >
              {globalState?.queryLoading && (
                <CircularProgress
                  size={18}
                  sx={{
                    mr: 1,
                    ml: -0.5,
                    color: "rgba(0,0,0,0.25)",
                  }}
                />
              )}
              Confirm {state.validated}
            </Button>
          </ScreenNavHeader>

          {state.loading && (
            <Stack my={4} alignItems={"center"}>
              <CircularProgress sx={{ color: "#2D9CDB" }} />
            </Stack>
          )}

          {!state.loading && (
            <Box flexGrow={1} mb={6}>
              <Stack
                mb={2}
                direction="column"
                spacing={2}
                alignItems={"center"}
              >
                <TextField
                  required
                  error={state.errors?.givenName ? true : false}
                  onChange={onChangeGivenName}
                  value={state.submission?.givenName || ""}
                  fullWidth={true}
                  label="First Name"
                />
                <TextField
                  required
                  error={state.errors?.familyName ? true : false}
                  onChange={onChangeFamilyName}
                  value={state.submission?.familyName || ""}
                  fullWidth={true}
                  label="Last Name"
                />
                <TextField
                  required
                  onChange={onChangeBio}
                  value={state.submission?.bio || ""}
                  error={state.errors?.bio ? true : false}
                  multiline
                  fullWidth={true}
                  minRows={4}
                  maxRows={6}
                  label="About Me (Optional)"
                />
                <TextField
                  required
                  error={state.errors?.email ? true : false}
                  onChange={onChangeEmail}
                  value={state.submission?.email || ""}
                  fullWidth={true}
                  label="Email"
                />
              </Stack>

              {!state.userData?.userId && p.journey !== "onboarding" ? (
                <>
                  <Skeleton
                    variant="circular"
                    sx={{ width: 196, height: 196, margin: "0 auto" }}
                  />
                </>
              ) : state.submission?.imagesData?.length >= 1 ||
                state?.userData?.dynamoDB?.images?.items?.[0]?.url ? (
                <>
                  <Box
                    component="img"
                    src={
                      state.submission?.imagesData?.[0]?.url ||
                      state?.userData?.dynamoDB?.images?.items?.[0]?.url
                    }
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: `${4 * 8}px auto ${4 * 4}px auto`,
                      height: 196,
                      width: 196,
                      borderRadius: "100%",
                      textAlign: "center",
                      textTransform: "none",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  ></Box>
                </>
              ) : (
                <UploaderButton
                  uploadDataInBrowser={attachPhotoHandler}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: `${4 * 8}px auto`,
                    background: "#F1F8F9",
                    height: 196,
                    width: 196,
                    border: "dashed 2px #B9DEF3",
                    borderRadius: "100%",
                    textAlign: "center",
                    textTransform: "none",
                  }}
                >
                  <Stack direction={"column"} spacing={1} alignItems={"center"}>
                    <SvgIcon sx={{ height: 48, width: 48, color: "#132B39" }}>
                      <AddPhotoAlternateIcon />
                    </SvgIcon>
                    <Typography
                      sx={{ color: "#132B39" }}
                      variant="h6"
                      fontWeight={"bold"}
                    >
                      Add Photo
                    </Typography>
                  </Stack>
                </UploaderButton>
              )}
              {(state.submission?.imagesData?.[0]?.url ||
                state?.userData?.dynamoDB?.images?.items?.[0]?.url) && (
                <Stack alignItems={"center"}>
                  {p.journey === "onboarding" ? (
                    <Button size="small" component="label">
                      Edit Photos
                      <VisuallyHiddenInput
                        type="file"
                        onChange={attachPhotoHandler}
                      />
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      component={ReactLink}
                      to={"/edit-profile/photos"}
                    >
                      {p.journey === "onboarding"
                        ? "Change Photo"
                        : "Edit Photos"}
                    </Button>
                  )}
                </Stack>
              )}
            </Box>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export const EditProfileScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <EditProfileScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

export const EditProfileScreenOnboardingLayout = (props) => {
  return (
    <OnboardingScreenResponsiveLayout waitForUserData={props.waitForUserData}>
      <EditProfileScreen {...props} />
    </OnboardingScreenResponsiveLayout>
  );
};

EditProfileScreen.propTypes = {};

EditProfileScreen.defaultProps = {
  color: "primary",
};
