import React, { useState, useContext, useRef } from "react";
import { Link as ReactLink, useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Fab from "@mui/material/Fab";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsIconSubbed from "../complex/NotificationsIconSubbed";
import SearchIcon from "@mui/icons-material/Search";
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import AddHomeOutlinedIcon from "@mui/icons-material/AddHome";
import EventIcon from "@mui/icons-material/Event";
import { Button as MuiButton, ButtonGroup } from "@mui/material";
import AppContext from "../../AppContext";  // Assuming you have an AppContext

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: "0 auto",
});

const PostBox = styled(Box)({
  position: "fixed",
  bottom: "56px",
  background: "white",
  width: "100%",
  left: "0px",
  borderRadius: "24px 24px 0 0",
  overflow: "hidden",
});

const PostBoxButton = styled(MuiButton)(({ theme }) => ({
  fontSize: "1.2em",
  textTransform: "none",
  color: "black",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

export function FooterAppBar(props) {
  const [postBoxOpen, setPostBoxOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [globalState, setGlobalStat, q, p] = useContext(AppContext);
  const homeScreenRef = useRef(null);

  const onClickCreateActivity = () => {
    setPostBoxOpen(false);
    navigate("/create-activity");
  };

  const onClickCreatePost = () => {
    setPostBoxOpen(false);
    navigate("/create-post");
  };

  const togglePostBox = () => {
    setPostBoxOpen(!postBoxOpen);
  };

  const tryScrollToTop = (e) => {
    if (e.currentTarget.getAttribute("href") === location.pathname) {
      e.preventDefault();
      
      if (location.pathname === "/") {
        if (p.listRef) {
          p.listRef.scrollTo(0, 0);
        } else {
          window.scrollTo(0, 0);
        }
      } else {
        window.scrollTo(0, 0);
      }
    }
  };

  return (
    <AppBar
      color="primary"
      position="fixed"
      sx={{
        top: "auto",
        bottom: 0,
        left: "50%",
        transform: "translate(-50%, 0)",
        color: "text.primary",
        boxShadow: "none",
        background: "white",
      }}
      {...props}
    >
      <Container
        maxWidth="sm"
        sx={{
          background: "white",
        }}
      >
        <Toolbar>
          <Stack
            width={"100%"}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <IconButton
              onClick={tryScrollToTop}
              component={ReactLink}
              to="/"
              color="inherit"
              aria-label="home"
            >
              <HomeOutlinedIcon />
            </IconButton>

            <IconButton
              onClick={tryScrollToTop}
              component={ReactLink}
              to="/contacts"
              color="inherit"
              aria-label="contacts"
            >
              <GroupOutlinedIcon />
            </IconButton>

            {postBoxOpen && (
              <PostBox>
                <Stack>
                  <ButtonGroup fullWidth>
                    <PostBoxButton
                      onClick={onClickCreatePost}
                      startIcon={
                        <SvgIcon>
                          <AddHomeOutlinedIcon />
                        </SvgIcon>
                      }
                      variant="text"
                    >
                      Post
                    </PostBoxButton>
                    <PostBoxButton
                      onClick={onClickCreateActivity}
                      startIcon={
                        <SvgIcon>
                          <EventIcon />
                        </SvgIcon>
                      }
                      variant="text"
                    >
                      Activity
                    </PostBoxButton>
                  </ButtonGroup>
                </Stack>
              </PostBox>
            )}

            <IconButton
              onClick={togglePostBox}
              size="large"
              sx={{ color: "#2D9CDB" }}
            >
              {postBoxOpen ? (
                <CloseIcon size="large" sx={{ fontWeight: "bold" }} />
              ) : (
                <AddIcon size="large" sx={{ fontWeight: "bold" }} />
              )}
            </IconButton>

            <IconButton 
            component={ReactLink}
            onClick={tryScrollToTop}
            to="/notifications"
            color="inherit"
            aria-label="notifications"
            >
              <NotificationsIconSubbed />
            </IconButton>

            <IconButton 
            onClick={tryScrollToTop}
            component={ReactLink} to="/search" color="inherit"
            aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
