import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import AppContext from "../../AppContext";
import {
  Avatar,
  Box,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
  Stack,
  SvgIcon,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import { AvatarLink } from "../basic/AvatarLink";
import { SearchTextField } from "../basic/SearchTextField";
import { TextField } from "../basic/TextField";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { generateClient, post } from "aws-amplify/api";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import sendChatMessageToUser from "./queries/sendChatMessageToUser.js";
import getChatChannelBySortedP2PUserIDS from "./queries/getChatChannelBySortedP2PUserIDS.js";
import onCreateChatChannelMessage from "./queries/onCreateChatChannelMessage.js";
import getUser from "./queries/getUser.js";
import moment from "moment";
import { cleanText } from "../../ui-components/utils.js";

const client = generateClient();

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

function convertTo24HourMinute(dateString) {
  const date = moment(dateString);

  const hours = date.format("HH");
  const minutes = date.format("mm");

  return `${hours}:${minutes}`;
}

/**
 * Primary UI component for user interaction
 */
export const ChatScreen = ({ ...props }) => {
  const params = useParams();

  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [state, setState] = useState({
    data: [],
    nextToken: null,
    loading: true,
  });

  const localP = useRef(null);
  const messageField = useRef(null);
  const chatBottom = useRef(null);
  const previousDateStamp = useRef(null);

  const fetchData = async () => {
    try {
      const ids = params.id.split(",");
      const receiverID = ids[0];
      const senderID = ids[1];

      const res = await client.graphql({
        //query: getChatChannel("31ac30b9-3909-4169-b849-5f0110483965"),
        //query: getChatChannel(globalState?.userData?.userId),
        query: getChatChannelBySortedP2PUserIDS(receiverID, senderID),
      });

      var resReceiver = await client.graphql({
        query: getUser(
          globalState?.userData?.userId === ids[0] ? ids[1] : ids[0]
        ),
      });

      var chatChannelData = res?.data?.q1 || res?.data?.q2;
      localP.current = {channelID: chatChannelData?.id};

      console.log("chatChannelData?.chatChannelMessages?.items", chatChannelData?.chatChannelMessages?.items)
      setState({
        ...state,
        text: "",
        // data: [...res?.data?.getChatChannel?.chatChannelMessages?.items],
        data: chatChannelData?.chatChannelMessages?.items
          ? [...chatChannelData?.chatChannelMessages?.items]
          : [],
        receiver: resReceiver?.data?.getUser,
        // res?.data?.getChatChannelBySortedP2PUserIDS?.chatChannelMembers
        // ?.items?.[1]?.user || resReceiver?.data?.getUser,
        // nextToken: res?.data?.getChatChannel?.nextToken,
        loading: false,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setState({ ...state, loading: false });
    }
  };

  const sendMessageHandler = async () => {
    if (state.text?.length >= 500) return;

    const textMessage = messageField.current.value;
    messageField.current.value = "";

    try {
      setState({
        ...state,
        text: "",
        data: [
          ...state.data,
          {
            content: {
              text: textMessage,
            },
            createdByID: globalState?.userData?.userId,
            createdAt: new Date(Date.now()).toISOString(),
            updatedAt: new Date(Date.now()).toISOString(),
            status: "submitted",
          },
        ],
      });

      const res = await client.graphql({
        query: sendChatMessageToUser(
          cleanText(textMessage),
          state.receiver?.id
        ),
      });
      fetchData();
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleTextSubmission = async (event) => {
    if (event.key === "Enter") {
      sendMessageHandler();
    }
  };

  useEffect(() => {
    // Subscribe to new notifications
    client.graphql({ query: onCreateChatChannelMessage() }).subscribe({
      next: (payload) => {
        // Parse the outer JSON string
        const incomingMessage = payload?.data?.onCreateChatChannelMessage;

        console.log("incomingMessage", incomingMessage);

        if (incomingMessage.channelID === localP.current?.channelID && incomingMessage.createdByID !== p?.userData?.userId) {
          setState((prevState) => {
            return {
              ...prevState,
              data: [
                ...(prevState.data || []), // Ensure array exists
                incomingMessage,
              ],
            };
          });
        }
      },
      error: (error) => console.warn(error),
    });
  }, []);

  useEffect(() => {
    (async () => {
      if (!globalState?.userData?.userId) return;
      fetchData();
    })();
  }, [globalState?.userData]);

  useEffect(() => {
    if (state.loading === true) return;

    chatBottom.current.scrollIntoView();
  }, [state]);

  return (
    <ThemeProvider theme={theme}>
      <Container
        sx={{ position: "sticky", top: 0 }}
        maxWidth={"sm"}
        ref={(thisElem) => (chatBottom.current = thisElem)}
      >
        <Box
          pt={1}
          px={2}
          sx={{
            position: "sticky",
            top: { xs: 56, sm: 0 },
            margin: `0px -16px`,
            zIndex: 2,
            backdropFilter: `blur(8px)`,
          }}
        >
          <Box>
            <ScreenNavHeader title="Messages" to="/contacts" />
          </Box>
          {!state.loading && (
            <AvatarLink
              mb={4}
              to={`/profile/${state?.receiver?.id}`}
              src={state?.receiver?.images?.items?.[0]?.url}
              avatarText={`${state?.receiver?.givenName} ${state?.receiver?.familyName}`}
            />
          )}
        </Box>

        <Stack
          direction={"column"}
          sx={{
            position: "sticky",
            top: 0,
            minHeight: { xs: `calc(100vh - 284px)`, md: `calc(100vh - 214px)` },
            paddingBottom: state.data.length > 10 ? 8 : 0,
          }}
        >
          {state.loading && (
            <Stack my={4} alignItems={"center"}>
              <CircularProgress sx={{ color: "#2D9CDB" }} />
            </Stack>
          )}

          {!state.loading &&
            state.data.map((chatObj, i) => {
              if (chatObj?.content?.text) {
                var show = 1;
                var currentDay = moment(chatObj?.createdAt).format(
                  "DD MMMM, YYYY"
                );

                if (
                  previousDateStamp.current === currentDay
                ) {
                  show = 0;
                } else {
                  show = 1;
                  previousDateStamp.current = currentDay;
                }

                if(
                  moment(state.data[state.data.length-1].createdAt).format(
                    "DD MMMM, YYYY"
                  ) === currentDay &&
                  i === 0
                ) {
                  show = 1;
                }

                console.log(previousDateStamp.current )
                return (
                  <>
                    {show === 1 && (
                      <Stack
                        direction="row"
                        my={2}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Paper
                          sx={{
                            p: 1,
                            opacity: 0.7,
                            boxShadow: "0px 0px 1px rgba(0,0,0,0.5)",
                          }}
                        >
                          <Typography variant="caption">
                            {moment(chatObj?.createdAt).format("DD MMMM, YYYY")}
                          </Typography>
                        </Paper>
                      </Stack>
                    )}
                    <Paper
                      sx={{
                        alignSelf:
                          chatObj?.createdByID === globalState?.userData?.userId
                            ? "flex-end"
                            : "flex-start",
                        width: "fit-content",
                        maxWidth: "65%",
                        background:
                          chatObj?.createdByID === globalState?.userData?.userId
                            ? "rgba(45, 156, 219, 0.1)"
                            : "rgba(0,0,0,0.06)",
                        mb: 2,
                        py: 1,
                        px: 1,
                      }}
                    >
                      <Stack
                        key={`comment-${i}`}
                        direction={"row"}
                        spacing={1}
                        alignItems={"flex-start"}
                      >
                        <Stack direction={"column"} sx={{ flexGrow: 1 }}>
                          {/* <Typography variant="body1">
                    {chatObj?.createdBy?.givenName}{" "}
                    {chatObj?.createdBy?.familyName}
                  </Typography> */}

                          <Typography
                            variant="body1"
                            sx={{ "word-break": "break-all" }}
                          >
                            {chatObj?.content?.text}
                          </Typography>
                        </Stack>
                        <Stack alignSelf={"end"} sx={{ mb: `-4px !important` }}>
                          <Typography variant="caption" sx={{ opacity: 0.5 }}>
                            <Stack direction={"row"} alignItems={"center"}>
                              {convertTo24HourMinute(
                                moment(chatObj?.updatedAt).toISOString(true)
                              )}
                              <SvgIcon sx={{ width: 16, height: 16 }}>
                                {chatObj.status === "submitted" ? (
                                  <DoneIcon />
                                ) : (
                                  <DoneAllIcon />
                                )}
                              </SvgIcon>
                            </Stack>
                          </Typography>
                        </Stack>
                      </Stack>
                    </Paper>
                  </>
                );
              }
            })}
        </Stack>

        {!state.loading && (
          <Box
            sx={{
              position: "sticky",
              margin: "0 -16px",
              padding: { xs: "6px 16px", sm: "8px 16px 16px 16px" },
              background: "white",
              bottom: { xs: 56, sm: 0 },
            }}
          >
            <SearchTextField
              onKeyUp={handleTextSubmission}
              inputRef={(thisElem) => (messageField.current = thisElem)}
              sx={{ width: "100%" }}
              placeholder="Message"
              onChange={(e) =>
                setState({ ...state, text: e.currentTarget.value })
              }
              value={state.text}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={sendMessageHandler}
                      aria-label="Message"
                      edge="end"
                    >
                      <SendOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {state.text?.length >= 450 && (
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ color: state.text?.length >= 450 ? "red" : "inherit" }}
              >
                Character count: {state.text?.length}
                /500
              </Typography>
            )}
          </Box>
        )}
      </Container>
      <div ref={(thisElem) => (chatBottom.current = thisElem)} />
    </ThemeProvider>
  );
};

export const ChatScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar
      pt={{ xs: 4, sm: 0 }}
      pb={{ xs: 6, sm: 0 }}
      waitForUserData={props.waitForUserData}
    >
      <ChatScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

ChatScreen.propTypes = {};

ChatScreen.defaultProps = {
  color: "primary",
};
