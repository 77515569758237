import React, { useContext, useEffect, useRef, useState } from "react";
import { Link as ReactLink, useNavigate, useParams } from "react-router-dom";
import AppContext from "../../AppContext";
import {
  IconButton,
  Box,
  Button as MuiButton,
  Container,
  Divider,
  InputBase,
  Stack,
  Switch as MuiSwitch,
  SvgIcon,
  Typography,
  CircularProgress,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import { LinkAttachment } from "../basic/LinkAttachment.jsx";
import { Button } from "../basic/Button";
import { CategoryChip } from "../basic/CategoryChip";
import { Select } from "../basic/Select";
import { Switch } from "../basic/Switch";
import { TextField } from "../basic/TextField";
import { ConditionsList } from "../complex/ConditionsList";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import DateDialog from "../complex/DateDialog";
import PaymentDialog from "../complex/PaymentDialog";
import { ResponsiveToaster } from "../basic/ResponsiveToaster.jsx";
import LinkInsert from "../complex/LinkInsert";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ReorderIcon from "@mui/icons-material/Reorder";
import TranslateIcon from "@mui/icons-material/Translate";
import useMediaQuery from "@mui/material/useMediaQuery";
import SendIcon from "@mui/icons-material/Send";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import getActivity from "./queries/getActivity.js";
import listLanguages from "./queries/listLanguages";
import { generateClient } from "aws-amplify/api";
import activityQueryString from "./activityQueryString.js";
const client = generateClient();

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

function ensureProtocolAndSubdomain(url) {
  // Check if URL has a protocol
  if (!/^https?:\/\//i.test(url)) {
    // If no protocol, add http:// as default
    url = "http://" + url;
  }

  try {
    // Create a URL object from the input URL
    const parsedUrl = new URL(url);

    // Check if the protocol is either 'http:' or 'https:'
    const hasValidProtocol =
      parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:";

    // Split the hostname into parts
    const hostnameParts = parsedUrl.hostname.split(".");

    // Check if there are at least three parts (subdomain.domain.tld)
    const hasSubdomain = hostnameParts.length > 2;

    return {
      url: parsedUrl.href, // Return the URL with protocol if it was missing
      hasValidProtocol,
      hasSubdomain,
    };
  } catch (e) {
    // URL constructor throws an error for invalid URLs
    return {
      url: null,
      hasValidProtocol: false,
      hasSubdomain: false,
    };
  }
}

function toNaturalNumber(num) {
  // Check if the number is positive
  if (num < 1) {
    return 1; // The smallest natural number
  }

  // Ensure the number is an integer
  return Math.floor(num);
}

function convertToCustomFormat(isoString) {
  // Create a Date object from the ISO string
  let date = new Date(isoString);

  // Get the components of the date
  let day = String(date.getDate()).padStart(2, "0");
  let month = date.getMonth(); // 0-indexed
  let year = date.getFullYear();

  // Get the components of the time
  let hours = date.getHours();
  let minutes = String(date.getMinutes()).padStart(2, "0");

  // Determine AM/PM period and convert hours to 12-hour format
  let period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert to 12-hour format

  // Month names array
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Format the final string
  let formattedDate = `${day} ${monthNames[month]}, ${year} / ${String(
    hours
  ).padStart(2, "0")}:${minutes} ${period}`;

  return formattedDate;
}

let formattedString = convertToCustomFormat("2026-01-01T15:30:00.000Z");

const capitalize = (string) => {
  return string?.[0]?.toUpperCase() + string?.slice(1)?.toLowerCase();
};

const privacyMap = {
  PUBLIC: "Public",
  JOIN_BY_REQUEST: "Join By Request",
  PRIVATE: "Private",
};

/**
 * Primary UI component for user interaction
 */
export const EditActivityScreen = ({ ...props }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [state, setState] = React.useState({
    paymentDialogOpen: false,
    dateDialogOpen: false,
    loading: true,
    validated: true,
    submission: {
      ...p.submission,
      activityName: p.submission?.activityName || "",
      activityDescription: p.submission?.activityDescription || "",
      numberOfParticipants: p.submission?.numberOfParticipants || 0,
      addressLine: p?.submission?.addressLine,
      fromAge: p.submission?.fromAge,
      toAge: p.submission?.toAge,
      conditions: p.submission?.conditions || [],
      interestLocationType: p.submission?.interestLocationType || "PHYSICAL",
      payment: p.submission?.payment
    },
  });
  const [toasterMessage, setToasterMessage] = useState();

  const timeoutId = useRef(null);
  const datetimeValues = useRef(null);

  useEffect(() => {
    (async () => {
      if (!p.notificationsInitialized) return;

      p.prevPage = p.currentPage || "edit-activity";
      p.currentPage = "edit-activity";
      p.editActivityID = params.id;
      p.activityMode = "edit";
      p.journey = "edit-activity";

      // If page was loaded previously, then ignore
      if (p.journey === "editing-activity") {
        setState({
          ...state,
          loading: false,
        });
        console.log("1");
        return;
      }

      if (
        [
          "activity-privacy",
          "category-selection",
          "subcategory-selection",
          "select-location",
          "add-photos",
          "select-languages",
        ].includes(p.prevPage)
      ) {
        setState({
          ...state,
          loading: false,
        });
        console.log("2");
        return;
      }

      console.log("3");

      setState({
        ...state,
        loading: true,
      });

      const res = await client.graphql({
        query: getActivity(params.id),
      });

      const languages_res = await client.graphql({
        query: listLanguages(),
      });

      // console.log('res.data.getActivity', res.data.getActivity)

      p.submission = {
        ...res.data.getActivity,
        activityName: res.data.getActivity.name,
        activityDescription: res.data.getActivity.description,
        conditions: res.data.getActivity?.conditions
          ? res.data.getActivity?.conditions?.map((v, i) => {
              return { content: v, id: (i + 1).toString() };
            })
          : [],
        // interestLocationType: res.data.getActivity.locationType,
        // numberOfParticipants: res.data.getActivity.numberOfParticipants,
        addressLine: res.data.getActivity?.addressLine,
        activityID: res.data?.getActivity?.id,
        interestLocationType: res.data?.getActivity?.locationType,
        selectedLanguagesNamed:
          languages_res?.data?.listLanguages?.items?.filter((obj) =>
            res.data?.getActivity?.languageIDS?.includes(obj.id)
          ),
        location: {
          latitude: res.data?.getActivity?.latitude,
          longitude: res.data?.getActivity?.longitude,
          q: res.data?.getActivity?.q || res.data?.getActivity?.address,
        },
        payment: {
          currency: res.data?.getActivity?.currency,
          price: res.data?.getActivity?.price || null,
          paymentMethod: res.data?.getActivity?.paymentMethod?.replace(
            " ",
            "_"
          ),
          link: {
            url: res.data?.getActivity?.paymentLink,
          },
        },
        currentCategoryID: res.data.getActivity.interestID,
        interestID: res.data.getActivity.interest.id,
        imagesData: res.data.getActivity.images?.items,
        files: res.data.getActivity.images?.items,
        ...p.submission,
      };

      // if(
      //   res.data?.getActivity?.currency &&

      // )

      console.log(".submission", p.submission)
      setState({
        ...state,
        loading: false,
        errors: { ...p.formErrors },
        submission: p.submission,
      });
    })();
  }, []);

  useEffect(() => {
    if (
      [
        "activity-privacy",
        "category-selection",
        "subcategory-selection",
        "select-location",
        "add-photos",
        "select-languages",
      ].includes(p.prevPage)
    ) {
      return;
    }

    // Validate fields
    // ---------------------------------------------------------------

    if (
      p.submission?.activityName &&
      p.submission?.activityDescription &&
      p.submission?.activityStart &&
      p.submission?.activityEnd &&
      p.submission?.interestLocationType &&
      p.submission?.currentCategoryID &&
      ((p.submission?.location?.latitude && p.submission?.addressLine) ||
        p.submission?.payment?.link?.url) &&
      p.submission?.numberOfParticipants >= 2 &&
      p.submission?.numberOfParticipants <= 50
    ) {
      if (!state.validated) {
        setState({
          ...state,
          validated: true,
        });
      }
    } else if (state.validated) {
      setState({
        ...state,
        validated: false,
      });
    }
  }, [state]);

  const toggleSwitch = (event) => {
    if (p.submission?.payment?.currency) {
      const confirmResponse = window.confirm(
        "Are you sure you want to delete the payment method?"
      );

      if (confirmResponse) {
        p.submission = {
          ...p.submission,
          payment: {
            ...p.submission?.payment,
            currency: null,
            price: null,
            paymentMethod: null,
            link: null,
          },
        };
        setState({
          ...state,
          submission: p.submission,
        });
      }
    } else {
      setState({
        ...state,
        paymentDialogOpen: true, //!state.paymentDialogOpen,
      });
    }
  };

  const addNewCondition = () => {
    const newItems = state.submission?.conditions
      ? [...state.submission.conditions]
      : [];

    if (
      !state.submission.conditions ||
      state.submission.conditions?.length <= 15
    ) {
      newItems.push({
        id: (newItems.length + 1).toString(),
        content: "",
      });

      p.submission = {
        ...p.submission,
        conditions: newItems,
      };

      setState({
        ...state,
        submission: p.submission,
      });
    }
  };

  const removeCondition = (event) => {
    const index = event.currentTarget.getAttribute("index");

    const newItems = state.submission?.conditions
      ? [...state.submission?.conditions]
      : [];

    newItems.splice(index, 1);

    p.submission = {
      ...p.submission,
      conditions: newItems,
    };

    setState({
      ...state,
      submission: p.submission,
    });
  };

  const onChipClick = (event) => {
    const categoryName = event.currentTarget.getAttribute("categoryName");
    navigate(`/edit-activity/category/${categoryName}`);
  };

  const onSwitchLocation = (switchIndex) => {
    // Set to gte if switch is upcoming and to lte if recent
    p.submission = {
      ...p.submission,
      locationType: switchIndex === 0 ? "PHYSICAL" : "ONLINE",
      interestLocationType: switchIndex === 0 ? "PHYSICAL" : "ONLINE",
    };

    setState({
      ...state,
      submission: p.submission,
    });
  };

  const onChangeActivityName = (event) => {
    const value = event.currentTarget.value;
    if (value.length >= 200) {
      p.submission.activityName = value;
      p.formErrors = {
        ...state.errors,
        activityName: "Enter a title",
      };
      setState({
        ...state,
        errors: p.errors,
        submission: p.submission,
      });
    } else {
      p.submission.activityName = value;
      p.formErrors = {
        ...state.errors,
        activityName: false,
      };
      setState({
        ...state,
        errors: p.errors,
        submission: p.submission,
      });
    }
  };

  const onChangeActivityDescription = (event) => {
    const value = event.currentTarget.value;
    if (value.length >= 900) {
      p.submission.activityDescription = value;
      p.formErrors = {
        ...state.errors,
        activityDescription: "Enter a title",
      };
      setState({
        ...state,
        submission: p.submission,
        errors: p.errors,
      });
    } else {
      p.submission.activityDescription = value;
      p.formErrors = {
        ...state.errors,
        activityDescription: false,
      };
      setState({
        ...state,
        submission: p.submission,
        errors: p.errors,
      });
    }
  };

  const onChangeNoAttendeesField = (event) => {
    const value = event.currentTarget.value;
    const regexPattern = /^[0-9]{1,3}$/;
    const isMatch = regexPattern.test(value);
    // Check the result
    if (2 <= parseInt(value) && parseInt(value) <= 50) {
      p.submission = {
        ...p.submission,
        numberOfParticipants: value,
      };
      p.formErrors = {
        ...state.errors,
        numberOfParticipants: false,
      };
      setState({
        ...state,
        submission: p.submission,
        errors: p.errors,
      });
    } else {
      if (event._reactName === "onBlur") {
        p.submission = {
          ...p.submission,
          numberOfParticipants: (() => {
            if (parseInt(value) < 2) {
              return 2;
            } else if (parseInt(value) > 50) {
              return 50;
            }
          })(),
        };
        p.formErrors = {
          ...state.errors,
          numberOfParticipants: false,
        };
        setState({
          ...state,
          submission: p.submission,
          errors: p.errors,
        });
      } else if (event._reactName === "onChange") {
        p.submission = {
          ...p.submission,
          numberOfParticipants: value,
        };
        p.formErrors = {
          ...state.errors,
          numberOfParticipants:
            "Enter a valid number for number of participants",
        };
        setState({
          ...state,
          submission: p.submission,
          errors: p.errors,
        });
      }
    }
  };

  const onChangeAgeField = (event) => {
    const value = event.currentTarget.value;
    if (typeof parseInt(value) !== "number") return;

    const fieldIndex = event.currentTarget.getAttribute("id");
    const minimum = 16;
    const maximum = 99;

    // Define your regex pattern
    const regexPattern = /^[0-9]{1,2}$/;

    // Use the test method
    const isMatch = regexPattern.test(value);

    var errorCount = 0;

    if (minimum) {
      if (parseInt(value) < minimum) {
        errorCount += 1;
      }
    }

    if (maximum) {
      if (parseInt(value) > maximum) {
        errorCount += 1;
      }
    }

    if (!isMatch) errorCount += 1;

    // Check the result
    if (errorCount === 0 || value.length === 0) {
      p.submission[fieldIndex] = value;
      p.formErrors = {
        ...state.errors,
        [fieldIndex]: false,
      };
      setState({
        ...state,
        [fieldIndex]: p.submission[fieldIndex],
        errors: p.errors,
      });
    } else {
      if (event._reactName === "onBlur") {
        p.submission[fieldIndex] = fieldIndex === "fromAge" ? 16 : 99;
        p.formErrors = {
          ...state.errors,
          [fieldIndex]: false,
        };
        setState({
          ...state,
          submission: p.submission,
          errors: p.errors,
        });
      } else {
        p.submission[fieldIndex] = value;
        p.formErrors = {
          ...state.errors,
          [fieldIndex]:
            "Enter a valid number for" +
            (fieldIndex === "toAge" ? "To" : "From") +
            "Age",
        };
        setState({
          ...state,
          submission: p.submission,
          errors: p.errors,
        });
      }
    }
  };

  const onAddLink = (url) => {
    p.submission = {
      ...p.submission,
      link: {
        url: url,
      },
    };
    setState({
      ...state,
      submission: p.submission,
    });
  };

  const onClearLink = () => {
    p.submission = {
      ...p.submission,
      link: null,
    };
    setState({
      ...state,
      submission: p.submission,
    });
  };

  const onChangeAddressLine = (event) => {
    p.submission.addressLine = event.currentTarget.value;
    setState({
      ...state,
      submission: p.submission,
    });
  };

  const onClickSave = async () => {
    const queryStringComplete = activityQueryString(p, "edit");
    setState({
      ...state,
      redirectLoading: true,
    });
    // console.log(queryStringComplete)
    q.updateState(globalState);
    const res = await q.doQuery(
      "updateActivity",
      "Activity updated",
      "An error occured",
      { submission: p.submission },
      p,
      queryStringComplete
    );

    if (res) {
      p.formErrors = null;
      navigate(`/activity/${state.submission?.activityID}`);
    }

    // console.log(res);
  };

  const onClickBack = () => {
    props.setAppDialog({
      ...props.appDialog,
      confirmText: "Exit",
      dialogChildren: (
        <Stack px={3}>
          <Typography variant="body1">
            Are you sure you want to leave this page?
            <br />
            Your changes will not be saved.
          </Typography>
        </Stack>
      ),
      open: true,
      onConfirm: async () => {
        navigate(-1);
      },
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Box>
          <Box mb={4}>
            <ScreenNavHeader onClick={onClickBack} title="Edit Activity">
              <Button
                disabled={globalState?.queryLoading}
                size={"large"}
                onClick={onClickSave}
              >
                {globalState?.queryLoading && (
                  <CircularProgress
                    size={18}
                    sx={{
                      mr: 1,
                      ml: -0.5,
                      color: "rgba(0,0,0,0.25)",
                    }}
                  />
                )}
                Save
              </Button>
            </ScreenNavHeader>
          </Box>

          {/* <Stack flexDirection={"column"} display="flex">
            <IconButton
              component={ReactLink}
              to="/invite"
              size="medium"
              sx={{
                position: "relative",
                right: -8,
                alignSelf: "end",
                marginTop: "-10px",
              }}
            >
              <SvgIcon sx={{ width: 18, height: 18 }}>
                <SendIcon />
              </SvgIcon>
            </IconButton>
            <Typography variant="subtitle2"></Typography>
          </Stack> */}

          {state.loading ? (
            <Stack my={4} alignItems={"center"}>
              <CircularProgress sx={{ color: "#2D9CDB" }} />
            </Stack>
          ) : (
            <>
              <Box mb={2}>
                <TextField
                  fullWidth
                  required
                  error={state.errors?.activityName ? true : false}
                  label="Activity Name"
                  onChange={onChangeActivityName}
                  value={state.submission?.activityName}
                />
              </Box>
              <Box mb={2}>
                <Stack spacing={1}>
                  <TextField
                    minRows={3}
                    fullWidth
                    error={state.errors?.activityDescription ? true : false}
                    multiline
                    label="Description"
                    onChange={onChangeActivityDescription}
                    value={state.submission?.activityDescription}
                  />
                  <Typography variant="caption" color="text.secondary">
                    Character count:{" "}
                    {state.submission?.activityDescription?.length}/900
                  </Typography>
                </Stack>
              </Box>

              <Stack mb={6}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography sx={{ minWidth: 200 }}>Privacy *</Typography>

                  <IconButton component={ReactLink} to="/edit-activity/privacy">
                    <SvgIcon>
                      <EditIcon />
                    </SvgIcon>
                  </IconButton>
                </Stack>

                <Typography>
                  {privacyMap[state.submission?.privacy?.toUpperCase()]}
                </Typography>
              </Stack>

              <Stack
                mb={6}
                alignItems={{ xs: "start", lg: "center" }}
                direction={{ xs: "column", md: "row" }}
                justifyContent={"space-between"}
                spacing={{ xs: 1, lg: 0 }}
              >
                <Typography sx={{ minWidth: 200 }}>
                  Activity Location *
                </Typography>

                {state.submission?.interestLocationType && (
                  <Switch
                    defaultIs0={
                      state.submission?.interestLocationType === "PHYSICAL"
                        ? true
                        : false
                    }
                    callback={onSwitchLocation}
                    optionA={"Physical"}
                    optionB={"Online"}
                    // width={useMediaQuery(theme.breakpoints.up("sm")) ? 552 : 320}
                    // width={useMediaQuery(theme.breakpoints.up("sm")) ? 320 : 220}
                    width={320}
                  />
                )}
              </Stack>
              <Stack mb={2} direction={"row"} justifyContent={"space-between"}>
                <Typography>Activity Category *</Typography>
                <IconButton component={ReactLink} to="/edit-activity/category">
                  <SvgIcon>
                    <EditIcon />
                  </SvgIcon>
                </IconButton>
              </Stack>
              <Box mb={6}>
                <Stack direction={"row"} flexWrap={"wrap"}>
                  {state.submission?.subcategoriesNamed ? (
                    Object.keys(state?.submission?.subcategoriesNamed)?.map(
                      (parentCategory, i) => {
                        return Object.values(
                          state.submission?.subcategoriesNamed?.[parentCategory]
                        )?.map((subCategory, j) => (
                          <CategoryChip
                            sx={{ marginRight: 1, marginBottom: 1 }}
                            onClick={onChipClick}
                            categoryName={`${parentCategory} - ${subCategory}`}
                            label={`${parentCategory} - ${subCategory}`}
                          />
                        ));
                      }
                    )
                  ) : (
                    <CategoryChip
                      sx={{ marginRight: 1, marginBottom: 1 }}
                      categoryName={`${state.submission?.interest?.parentInterest?.title} - ${state.submission?.interest?.title}`}
                      label={`${state.submission?.interest?.parentInterest?.title} - ${state.submission?.interest?.title}`}
                    />
                  )}
                </Stack>
              </Box>
              <Stack mb={2} direction={"row"} justifyContent={"space-between"}>
                <Typography>Date &amp; Time * </Typography>
                <IconButton
                  onClick={() =>
                    setState({
                      ...state,
                      dateDialogOpen: true,
                    })
                  }
                >
                  <SvgIcon>
                    <EditIcon />
                  </SvgIcon>
                </IconButton>
              </Stack>
              <DateDialog
                ref={(thisElem) => (datetimeValues.current = thisElem)}
                title="Time and Date"
                open={state.dateDialogOpen}
                startDate={state?.submission?.activityStart}
                endDate={state?.submission?.activityEnd}
                buttonDisabled={state.buttonDisabled}
                onChangeCallback={(isValid) =>
                  setState({
                    ...state,
                    buttonDisabled: !isValid,
                  })
                }
                onConfirm={() => {
                  setState({
                    ...state,
                    submission: {
                      ...state.submission,
                      activityStart:
                        datetimeValues?.current?.value?.split(",")?.[0],
                      activityEnd:
                        datetimeValues?.current?.value?.split(",")?.[1],
                    },
                  });
                  setState({
                    ...state,
                    dateDialogOpen: false,
                  });
                }}
                onClose={() =>
                  setState({
                    ...state,
                    dateDialogOpen: false,
                  })
                }
              />

              {!state?.submission?.activityStart &&
                !state?.submission?.activityEnd && (
                  <Stack
                    mb={6}
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                  >
                    <SvgIcon>
                      <DateRangeIcon />
                    </SvgIcon>
                    <Typography variant="body2">
                      Select start and end times.
                    </Typography>
                  </Stack>
                )}
              {state?.submission?.activityStart &&
                state?.submission?.activityEnd && (
                  <>
                    <Stack
                      mb={1}
                      direction={"row"}
                      spacing={1}
                      alignItems={"center"}
                    >
                      <SvgIcon>
                        <DateRangeIcon />
                      </SvgIcon>
                      <Typography variant="body2">
                        {convertToCustomFormat(
                          state?.submission?.activityStart
                        )}
                      </Typography>
                    </Stack>
                    <Stack
                      mb={6}
                      direction={"row"}
                      spacing={1}
                      alignItems={"center"}
                    >
                      <SvgIcon>
                        <DateRangeIcon />
                      </SvgIcon>
                      <Typography variant="body2">
                        {convertToCustomFormat(state?.submission?.activityEnd)}
                      </Typography>
                    </Stack>
                  </>
                )}

              <>
                <Stack
                  mb={2}
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Typography>Location*</Typography>
                  {state.submission?.interestLocationType === "PHYSICAL" && (
                    <IconButton
                      component={ReactLink}
                      to="/edit-activity/select-location"
                    >
                      <SvgIcon>
                        <EditIcon />
                      </SvgIcon>
                    </IconButton>
                  )}
                </Stack>

                {state.submission?.interestLocationType === "PHYSICAL" && (
                  <>
                    <Box mb={2}>
                      {state?.submission?.location?.q ? (
                        <Typography variant="body1">
                          {state?.submission?.location?.q}
                        </Typography>
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          <em>Select the location</em>
                        </Typography>
                      )}
                    </Box>
                    <Box mb={1}>
                      <iframe
                        width="100%"
                        height="200"
                        style={{ border: "0" }}
                        loading="lazy"
                        allowFullScreen
                        referrerPolicy="no-referrer-when-downgrade"
                        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCK_7n9C93rb_5VmGoqAyTr-FlceCui28U
              &q=${state?.submission?.location?.latitude},${state?.submission?.location?.longitude}`}
                      ></iframe>
                    </Box>

                    <Box mb={6}>
                      <TextField
                        onChange={onChangeAddressLine}
                        label="Address Description"
                        fullWidth
                        value={state.submission?.addressLine}
                      />
                    </Box>
                  </>
                )}

                {state.submission?.interestLocationType === "ONLINE" && (
                  <Box mb={6}>
                    {!state.submission?.link?.url && (
                      <LinkInsert onClickHandler={onAddLink} callback={null} />
                    )}

                    {/* Link Preview */}
                    {state.submission?.link?.url && (
                      <LinkAttachment
                        onClearLink={onClearLink}
                        link={
                          ensureProtocolAndSubdomain(
                            state.submission?.link?.url
                          ).url
                        }
                      />
                    )}
                  </Box>
                )}
              </>
              <Stack
                mb={6}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box>
                  <Typography gutterBottom>Number of Attendees*</Typography>
                  <Typography gutterBottom variant="body2">
                    Including activity organizer
                  </Typography>
                </Box>
                <TextField
                  required
                  error={state.errors?.numberOfParticipants ? true : false}
                  placeholder="2+"
                  size="medium"
                  type="number"
                  sx={{ width: 68, textAlign: "center" }}
                  onChange={onChangeNoAttendeesField}
                  onBlur={onChangeNoAttendeesField}
                  value={state.submission?.numberOfParticipants}
                  InputProps={{ inputProps: { min: 2, max: 50 } }}
                />
              </Stack>
              <Stack direction="row" justifyContent={"space-between"} mb={2}>
                <Box>
                  <Typography gutterBottom>Paid Activity (optional)</Typography>
                  <Typography gutterBottom variant="body2">
                    Please note that we do not process payments
                  </Typography>
                </Box>

                <MuiSwitch
                  checked={state.submission?.payment?.currency ? true : false}
                  onChange={toggleSwitch}
                />
              </Stack>
              {(state.submission?.payment?.currency ||
                state.submission?.payment?.price ||
                state.submission?.payment?.paymentMethod ) && (
                <Stack direction="row" alignItems={"start"} spacing={1} mb={6}>
                  <Box flexGrow={1}>
                    <Stack direction={"row"} spacing={1} mb={2}>
                      <Typography>
                        {state.submission?.payment?.currency}
                      </Typography>
                      <Typography sx={{ width: 42 }}>
                        {parseFloat(
                          state.submission?.payment?.price
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                    </Stack>

                    <Box>
                      <Typography gutterBottom>
                        Payment method:{" "}
                        {capitalize(
                          state.submission?.payment?.paymentMethod?.replace(
                            "_",
                            " "
                          )
                        )}
                      </Typography>
                      <Typography>
                        <ReactLink
                          target="_blank"
                          to={
                            ensureProtocolAndSubdomain(
                              state.submission?.payment?.link?.url
                            ).url
                          }
                        >
                          {state.submission?.payment?.link?.url}
                        </ReactLink>
                      </Typography>
                    </Box>
                  </Box>

                  <IconButton
                    onClick={() =>
                      setState({ ...state, paymentDialogOpen: true })
                    }
                  >
                    <SvgIcon
                      sx={{ marginLeft: "0 !important", fontWeight: 700 }}
                    >
                      <EditIcon />
                    </SvgIcon>
                  </IconButton>
                </Stack>
              )}
              <PaymentDialog
                title="Price and Payment"
                open={state.paymentDialogOpen}
                p={p}
                onConfirmCallback={(updatedPaymentObj) => {
                  p.submission = {
                    ...p.submission,
                    payment: updatedPaymentObj,
                  };
                  setState({
                    ...state,
                    paymentDialogOpen: false,
                    submission: p.submission,
                  });
                }}
                onClose={() => setState({ ...state, paymentDialogOpen: false })}
              />
              <Box mb={6}>
                <Divider />
              </Box>
              <Stack mb={1} direction={"row"} justifyContent={"space-between"}>
                <Typography gutterBottom>Photos (optional)</Typography>
                <IconButton component={ReactLink} to="/edit-activity/photos">
                  <SvgIcon>
                    <EditIcon />
                  </SvgIcon>
                </IconButton>
              </Stack>
              <Box sx={{ overflowX: "scroll" }} mb={6}>
                <Stack
                  direction={"row"}
                  spacing={2}
                  sx={{ width: "fit-content" }}
                >
                  {state?.submission?.imagesData?.map((imageObj, i) => (
                    <Box
                      key={`image-${i}`}
                      sx={{
                        borderRadius: 4,
                        height: { xs: 165 },
                        width: { xs: 165 },
                        background: `url(${imageObj.url}) center`,
                        backgroundSize: "cover",
                      }}
                      src={imageObj.url}
                      alt={imageObj.title}
                    />
                  ))}
                </Stack>
              </Box>
              <Box mb={6}>
                <Typography gutterBottom>Conditions (optional)</Typography>
                <Box py={2}>
                  {/* {
                [1,2,3].map(
                  ()=><Stack direction="row" spacing={1}>
                    <SvgIcon><ReorderIcon/></SvgIcon>
                    <Typography>Don't be late</Typography>
                  </Stack>
                )
              } */}

                  <ConditionsList
                    items={state.submission?.conditions}
                    setConditions={(items) =>
                      setState({ ...state, conditions: items })
                    }
                    removeHandler={removeCondition}
                  />
                </Box>

                <MuiButton
                  variant="text"
                  startIcon={
                    <SvgIcon>
                      <AddIcon />
                    </SvgIcon>
                  }
                  size="large"
                  onClick={addNewCondition}
                  sx={{
                    color: "#000",
                    textTransform: "none",
                  }}
                >
                  Add a condition
                </MuiButton>
              </Box>
              <Box mb={6}>
                <Box pb={2}>
                  <Typography gutterBottom>
                    Age restrictions (optional)
                  </Typography>
                </Box>

                <Stack
                  spacing={2}
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <TextField
                    fullWidth
                    error={state.errors?.fromAge ? true : false}
                    label={"From age"}
                    type="number"
                    id="fromAge"
                    onChange={onChangeAgeField}
                    onBlur={onChangeAgeField}
                    InputProps={{ inputProps: { min: 16, max: 99 } }}
                    value={state.submission?.fromAge}
                  />
                  <TextField
                    fullWidth
                    error={state.errors?.toAge ? true : false}
                    id="toAge"
                    type="number"
                    label={"To age"}
                    onChange={onChangeAgeField}
                    onBlur={onChangeAgeField}
                    InputProps={{ inputProps: { min: 16, max: 99 } }}
                    value={state.submission?.toAge}
                  />
                </Stack>
              </Box>
              <Box mb={6}>
                <Box pb={2}>
                  <Stack
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    direction="row"
                  >
                    <Typography gutterBottom>Languages (optional)</Typography>
                    <IconButton
                      component={ReactLink}
                      to="/edit-activity/select-language"
                    >
                      <SvgIcon>
                        <EditIcon />
                      </SvgIcon>
                    </IconButton>
                  </Stack>
                </Box>

                {state.submission?.selectedLanguagesNamed && (
                  <Stack direction={"row"} spacing={2}>
                    <SvgIcon>
                      <TranslateIcon />
                    </SvgIcon>
                    <Typography>
                      {state.submission?.selectedLanguagesNamed?.map((v, i) =>
                        `${v.name}`.concat(
                          i <
                            state.submission?.selectedLanguagesNamed?.length - 1
                            ? `, `
                            : ""
                        )
                      )}
                    </Typography>
                  </Stack>
                )}
              </Box>
            </>
          )}
        </Box>

        <ResponsiveToaster
          sx={{ position: "sticky" }}
          severity={state.severity}
          toasterMessage={toasterMessage}
          setToasterMessage={setToasterMessage}
        />
      </Container>
    </ThemeProvider>
  );
};

export const EditActivityScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <EditActivityScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

EditActivityScreen.propTypes = {
  paid: PropTypes.bool,
  currency: PropTypes.string,
  price: PropTypes.number,
};

EditActivityScreen.defaultProps = {
  color: "primary",
};
